import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  subscriptionDocumentValidationStatus,
  type SubscriptionDocumentType,
  type SubscriptionDocumentValidationStatus,
} from '@orus.eu/dimensions'
import { memo, useCallback, useEffect, useState, type ChangeEvent } from 'react'
import { colorTokens } from '../../../../foundation/color-tokens'
import { spacing } from '../../../../foundation/spacing-tokens'
import { timeAgo } from '../../../../lib/time'
import { Avatar } from '../../../atoms'
import { Text } from '../../../atoms/text'
import { Textarea } from '../../../atoms/textarea'
import { Chip } from '../../../chip'
import { Select } from '../../../inputs'
import { TextField } from '../../../inputs/text-field'
import { RowContainerV2 } from '../../../molecules'
import { Tooltip } from '../../../tooltip'
import type { SubscriptionDocumentNoteValue } from '../subscription-document-note-dialog/types'
import {
  subscriptionDocumentBackgroundColor,
  subscriptionDocumentStatusSubtitleText,
  subscriptionDocumentStatusText,
  subscriptionDocumentTextColor,
  subscriptionDocumentsConfiguration,
} from '../subscription-document-upload-dialog/subscription-document-text'

type DocumentViewerMenuProps = {
  documentType: SubscriptionDocumentType
  filesLength: number
  fileIndex: number
  name: string
  description: string
  documentStatus: SubscriptionDocumentValidationStatus
  onStatusChange: (newStatus: string | null) => void
  onFileDescriptionChange: (description: string) => void
  onNoteChange: (note: SubscriptionDocumentNoteValue) => void
  documentNotes: SubscriptionDocumentNoteValue
  fileTimestamp: number
}

export const DocumentViewerMenu = memo<DocumentViewerMenuProps>(function DocumentViewerMenu({
  fileIndex,
  filesLength,
  name,
  description,
  documentStatus,
  onStatusChange,
  onFileDescriptionChange,
  onNoteChange,
  documentNotes,
  fileTimestamp,
  documentType,
}) {
  const updatableStatus = subscriptionDocumentValidationStatus.filter((status) => status !== 'none')
  const { acceptedDocuments, requiredConditions } = subscriptionDocumentsConfiguration[documentType]

  const [localNotes, setLocalNotes] = useState<SubscriptionDocumentNoteValue | undefined>()

  const handleDocumentLocalNoteChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalNotes({
      value: event.target.value,
      valueTimestamp: Date.now(),
    })
  }, [])

  const [localDescription, setLocalDescription] = useState<string | undefined>()

  useEffect(() => {
    setLocalDescription(description)
  }, [description])

  useEffect(() => {
    setLocalNotes(documentNotes)
  }, [documentNotes])

  return (
    <SidebarContainer>
      <RowContainerV2 noContainedBorder>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Fichier </Text>
            <Chip
              size="small"
              textColor={colorTokens['color-text-base-secondary']}
              backgroundColor={colorTokens['color-bg-neutral-inverse']}
            >
              {fileIndex} sur {filesLength}
            </Chip>
          </FileHeaderContainer>
          <FileNameAndTimestampContainer>
            <Text variant="body2Medium">{name}</Text>
            <Text variant="body2">{timeAgo(fileTimestamp)}</Text>
          </FileNameAndTimestampContainer>
          <TextField
            size="small"
            placeholder="Nom du fichier"
            onChange={(e) => setLocalDescription(e.target.value)}
            onBlur={(e) => onFileDescriptionChange(e.target.value)}
            value={localDescription}
          />
        </SubSectionContainer>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Détails de la pièce</Text>
          </FileHeaderContainer>
          <DocumentStatusContainer>
            <DocumentStatusLabelContainer>
              <Chip
                size="small"
                textColor={subscriptionDocumentTextColor[documentStatus]}
                backgroundColor={subscriptionDocumentBackgroundColor[documentStatus]}
              >
                {subscriptionDocumentStatusText[documentStatus]}
              </Chip>
              <Text variant="body2">{subscriptionDocumentStatusSubtitleText[documentStatus]}</Text>
            </DocumentStatusLabelContainer>
            <Select
              aria-label="Statut de validation du document"
              value={documentStatus}
              values={updatableStatus}
              onChange={onStatusChange}
              size="small"
              placeholder="Statut"
              labels={subscriptionDocumentStatusText}
            />
          </DocumentStatusContainer>
          <DocumentInfoRowContainer>
            {acceptedDocuments ? (
              <DocumentInfoRow>
                <Text variant="body2Medium">Documents acceptés</Text>
                <Tooltip title={acceptedDocuments.join(' ')}>
                  <Avatar variant="full" icon="circle-info-regular" size="30" />
                </Tooltip>
              </DocumentInfoRow>
            ) : undefined}
            {requiredConditions ? (
              <DocumentInfoRow>
                <Text variant="body2Medium">Conditions requises</Text>
                <Tooltip title={requiredConditions.join(' ')}>
                  <Avatar variant="full" icon="circle-info-regular" size="30" />
                </Tooltip>
              </DocumentInfoRow>
            ) : undefined}
          </DocumentInfoRowContainer>
        </SubSectionContainer>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Notes</Text>
          </FileHeaderContainer>

          <Textarea
            size="small"
            placeholder="Saisir une note"
            onChange={handleDocumentLocalNoteChange}
            onBlur={() => {
              if (localNotes) onNoteChange(localNotes)
            }}
            value={localNotes?.value ?? ''}
            minRows={3}
            maxRows={5}
          />
          {localNotes?.value && localNotes?.valueTimestamp ? (
            <Text
              variant="caption"
              color={colorTokens['color-text-base-basic']}
              css={css`
                margin-top: ${spacing['20']};
              `}
            >
              {timeAgo(localNotes.valueTimestamp)}
            </Text>
          ) : null}
        </SubSectionContainer>
      </RowContainerV2>
    </SidebarContainer>
  )
})

const SidebarContainer = styled.div`
  overflow-y: scroll;
  width: 380px;
  max-width: fit-content;
`

const SubSectionContainer = styled('div')`
  display: flex;
  padding: ${spacing['60']};
  flex-direction: column;
  align-items: left;
  gap: ${spacing['50']};
  align-self: stretch;

  :not(:first-of-type) {
    border-top: 1px solid ${colorTokens['color-stroke-base']};
  }
`
const FileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing['30']};
  align-self: stretch;
`

const FileNameAndTimestampContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const DocumentStatusLabelContainer = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['30']};
  align-self: stretch;
`

const DocumentStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['50']};
  align-self: stretch;
`
const DocumentInfoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const DocumentInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  background: none;
  padding: ${spacing['30']} 0;
`
