import type { Subscribable } from '@orus.eu/observable'
import { useEffect, useState } from 'react'

export function useSinglettonState<T>(subscribable: Subscribable<T>): [T, (value: T | ((value: T) => T)) => void] {
  const [value, setValue] = useState(subscribable.getValue())

  useEffect(() => subscribable.subscribe(setValue), [subscribable])

  return [value, subscribable.setValue]
}
