import { type SubscriptionDocumentsUiElement } from '@orus.eu/dimensions'
import { Divider, FlexColumn, spacing } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { ifStateProxy } from '../../if-state-proxy'
import { DocumentList } from './document-list'
import { DocumentOverview } from './document-overview'

export const SubscriptionDocumentsUiElementBlock = ifStateProxy<SubscriptionDocumentsUiElement>(
  function SubscriptionDocumentsUiElementBlock({ uiElement, stateProxy, subscriptionId, versionedSubscriptionId }) {
    const neededDocuments = stateProxy.readRequired(uiElement.dimensions.neededSubscriptionDocuments)
    const globalNote = stateProxy.readRequired(uiElement.dimensions.globalDocumentNote)

    const handleGlobalOverviewNoteChange = useCallback(
      (value: string) => {
        stateProxy.write(uiElement.dimensions.globalDocumentNote, value)
      },
      [stateProxy, uiElement.dimensions.globalDocumentNote],
    )

    return (
      <FlexColumn gap={spacing[60]}>
        <DocumentList
          stateProxy={stateProxy}
          subscriptionId={subscriptionId}
          neededDocuments={neededDocuments}
          isBackoffice={true}
        />
        <Divider orientation="horizontal" />
        <DocumentOverview
          versionedSubscriptionId={versionedSubscriptionId}
          subscriptionId={subscriptionId}
          stateProxy={stateProxy}
          neededDocuments={neededDocuments}
          globalOverviewNote={globalNote}
          handleGlobalOverviewNoteChange={handleGlobalOverviewNoteChange}
        />
      </FlexColumn>
    )
  },
)
