import { css } from '@emotion/react'
import { PARIS, calendarDateToString, type CalendarDate } from '@orus.eu/calendar-date'
import {
  Avatar,
  RowButtonV2,
  RowContainerV2,
  colors,
  spacing,
  useDialogVisibility,
  useLanguage,
} from '@orus.eu/pharaoh'
import { FamilyMembersListModal, MemberModal } from '@orus.eu/pharaoh/src/components/features/health-cover'
import child from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/child.png'
import kiss from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/kiss.png'
import personWhiteHair from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/person-white-hair.png'

import {
  mutaBeneficiaryTypes,
  mutaSocialSecurityRegimeEntries,
  type AddBeneficiariesSubscriptionUiElement,
  type MutaBeneficiary,
  type MutaBeneficiaryType,
  type MutaSocialSecurityRegimeType,
} from '@orus.eu/dimensions'
import { memo, useCallback, useMemo, useState, type FunctionComponent } from 'react'
import { ifStateProxy } from '../if-state-proxy'

type BeneficiaryMemberModalType =
  | { type: 'create'; beneficiaryType: MutaBeneficiaryType }
  | { type: 'update'; beneficiaryToUpdate: MutaBeneficiary; index: number }

export const AddBeneficiariesUiElementBlock = ifStateProxy<AddBeneficiariesSubscriptionUiElement>(
  function AddBeneficiariesSubscriptionUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const [beneficiaries, setBeneficiaries] = useState(stateProxy.read(uiElement.dimension) ?? [])
    const [currentMemberModalType, setCurrentMemberModalType] = useState<BeneficiaryMemberModalType>({
      type: 'create',
      beneficiaryType: 'PARTNER',
    })

    const {
      show: showBeneficiaryMenu,
      hide: hideBeneficiaryMenu,
      visible: visibleBeneficiaryMenu,
    } = useDialogVisibility('add-member-menu')

    const {
      show: showBeneficiaryModal,
      hide: hideBeneficiaryModal,
      visible: visibleBeneficiaryModal,
    } = useDialogVisibility(`add-member`)

    const modalFamilyMembers = useMemo(() => {
      return familyMembers(
        setCurrentMemberModalType,
        showBeneficiaryModal,
        !!beneficiaries.find((b) => b.type === 'PARTNER'),
      )
    }, [beneficiaries, showBeneficiaryModal])

    const onSubmitMember = useCallback(
      (beneficiary: MutaBeneficiary) => {
        const beneficiariesToUpdate = [...beneficiaries]
        if (currentMemberModalType.type === 'create') {
          beneficiariesToUpdate.push(beneficiary)
        } else {
          beneficiariesToUpdate[currentMemberModalType.index] = beneficiary
        }

        stateProxy.write(uiElement.dimension, beneficiariesToUpdate)
        setBeneficiaries(beneficiariesToUpdate)
        hideBeneficiaryModal()
      },
      [beneficiaries, currentMemberModalType, hideBeneficiaryModal, stateProxy, uiElement.dimension],
    )

    const onDeleteMember = useCallback(() => {
      if (currentMemberModalType.type != 'update') return
      const beneficiariesToUpdate = [...beneficiaries]
      beneficiariesToUpdate.splice(currentMemberModalType.index, 1)

      stateProxy.write(uiElement.dimension, beneficiariesToUpdate)
      setBeneficiaries(beneficiariesToUpdate)
      hideBeneficiaryModal()
    }, [beneficiaries, currentMemberModalType, hideBeneficiaryModal, stateProxy, uiElement.dimension])

    const onBeneficiaryClick = useCallback(
      (beneficiary: MutaBeneficiary, index: number) => {
        setCurrentMemberModalType({ type: 'update', beneficiaryToUpdate: beneficiary, index })
        showBeneficiaryModal()
      },
      [showBeneficiaryModal],
    )

    return (
      <>
        {visibleBeneficiaryMenu ? (
          <FamilyMembersListModal onClose={hideBeneficiaryMenu} familyMembers={modalFamilyMembers} />
        ) : (
          <></>
        )}
        {visibleBeneficiaryModal ? (
          <MemberModalWrapper
            onClose={hideBeneficiaryModal}
            onSubmitMember={onSubmitMember}
            onDeleteMember={onDeleteMember}
            {...currentMemberModalType}
          />
        ) : (
          <></>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          {beneficiaries.length > 0 ? (
            <RowContainerV2>
              {beneficiaries.map((beneficiary, index) => {
                return (
                  <RowButtonV2
                    key={beneficiary.firstName + '-' + beneficiary.lastName}
                    avatarLeft={<Avatar src={avatarPerBeneficiaryType[beneficiary.type]} size="50" />}
                    primaryText={beneficiary.firstName}
                    secondaryText={
                      beneficiary.birthDate
                        ? calendarDateToString(beneficiary.birthDate, PARIS, 'DATE_SHORT', language) +
                          ' - ' +
                          namingPerBeneficiaryType[beneficiary.type]
                        : namingPerBeneficiaryType[beneficiary.type]
                    }
                    onClick={() => onBeneficiaryClick(beneficiary, index)}
                  />
                )
              })}
            </RowContainerV2>
          ) : (
            <></>
          )}

          <RowContainerV2>
            <RowButtonV2
              avatarLeft={
                <Avatar
                  variant="contained"
                  icon={'plus-solid'}
                  containerColor={colors.blue[100]}
                  color={colors.blue[600]}
                  size="50"
                />
              }
              primaryText="Ajouter un membre"
              onClick={showBeneficiaryMenu}
            />
          </RowContainerV2>
        </div>
      </>
    )
  },
)

type MemberModalWrapperProps = {
  onClose: () => void
  onSubmitMember: (beneficiary: MutaBeneficiary) => void
  onDeleteMember: () => void
} & BeneficiaryMemberModalType

type UpdateBeneficiary = {
  firstName?: string
  birthDate?: CalendarDate
  zipCode?: string
  isDisabledAdult?: boolean
  isLinkedToPartner?: boolean
  regime?: MutaSocialSecurityRegimeType
}

const MemberModalWrapper: FunctionComponent<MemberModalWrapperProps> = memo(function MemberModalWrapper(
  props: MemberModalWrapperProps,
): JSX.Element {
  const { onSubmitMember, onClose, onDeleteMember, type } = props

  const canDelete = useMemo(() => {
    return type === 'update'
  }, [type])

  const beneficiaryType = useMemo(() => {
    return props.type === 'create' ? props.beneficiaryType : props.beneficiaryToUpdate.type
  }, [props])

  const isChild = useMemo(() => {
    return beneficiaryType === 'CHILD'
  }, [beneficiaryType])

  const [isDisabledAdult, setDisabledAdult] = useState(false)
  const [linkedFamilyMember, setLinkedFamilyMember] = useState<'YOU' | 'PARTNER'>('YOU')

  const [idleBeneficiary, setIdleBeneficiary] = useState<MutaBeneficiary>(
    type === 'update'
      ? props.beneficiaryToUpdate
      : {
          type: beneficiaryType,
          ...(isChild && { isDisabledAdult, isLinkedToPartner: linkedFamilyMember !== 'YOU' }),
        },
  )

  const updateBeneficiary = useCallback(
    (update: UpdateBeneficiary) => {
      const updateIdleBeneficiary = { ...idleBeneficiary, ...update }
      setIdleBeneficiary(updateIdleBeneficiary)
    },
    [idleBeneficiary],
  )

  const canSubmit = useMemo(() => {
    if (!idleBeneficiary) return false
    if (idleBeneficiary.regime && idleBeneficiary.firstName && idleBeneficiary.zipCode && idleBeneficiary.birthDate)
      return true
    return false
  }, [idleBeneficiary])

  const onSubmit = useCallback(() => {
    if (!canSubmit) return
    onSubmitMember(idleBeneficiary)
  }, [canSubmit, idleBeneficiary, onSubmitMember])

  const onDelete = useCallback(() => {
    if (!canDelete) return
    onDeleteMember()
  }, [canDelete, onDeleteMember])

  const handleOnIsDisableAdultChanged = useCallback(
    (newValue: boolean) => {
      if (isChild) {
        setDisabledAdult(newValue)
        updateBeneficiary({ isDisabledAdult: newValue })
      }
    },
    [isChild, updateBeneficiary],
  )

  const handleChangeLinkedFamilyMember = useCallback(
    (newValue: string | null) => {
      if (isChild) {
        if (newValue === 'YOU' || newValue === 'PARTNER') {
          setLinkedFamilyMember(newValue)
          updateBeneficiary({ isLinkedToPartner: newValue === 'PARTNER' ? true : false })
        }
      }
    },
    [isChild, updateBeneficiary],
  )

  const handleChangeFirstName = useCallback(
    (value: string) => updateBeneficiary({ firstName: value }),
    [updateBeneficiary],
  )

  const handleChangeBirthdate = useCallback(
    (value: CalendarDate | null) => updateBeneficiary({ birthDate: value === null ? undefined : value }),
    [updateBeneficiary],
  )

  const handleChangePostCode = useCallback(
    (value: string) => updateBeneficiary({ zipCode: value }),
    [updateBeneficiary],
  )

  const handleChangeSocialRegime = useCallback(
    (value: string) => {
      const regime = mutaSocialSecurityRegimeEntries.find((regime) => regime[1].label === value)?.[0]
      updateBeneficiary({ regime })
    },
    [updateBeneficiary],
  )

  const regimes = useMemo(() => {
    return mutaSocialSecurityRegimeEntries.map((regime) => {
      return { title: regime[1].label, tooltip: regime[1].tooltip }
    })
  }, [])

  const socialRegimeValue = useMemo(
    () =>
      idleBeneficiary?.regime
        ? (mutaSocialSecurityRegimeEntries.find((regime) => regime[0] === idleBeneficiary?.regime)?.[1].label ?? '')
        : '',
    [idleBeneficiary],
  )

  return (
    <MemberModal
      emoji={avatarPerBeneficiaryType[beneficiaryType]}
      title={namingPerBeneficiaryType[beneficiaryType]}
      lastNameValue={''}
      firstNameValue={idleBeneficiary?.firstName ?? ''}
      firstNameLastNameAlreadyExists={false}
      birthdateValue={{
        year: idleBeneficiary?.birthDate?.year ?? 0,
        oneBasedMonth: idleBeneficiary?.birthDate?.oneBasedMonth ?? 0,
        oneBasedDay: idleBeneficiary?.birthDate?.oneBasedDay ?? 0,
      }}
      cityValue={''}
      postCodeValue={idleBeneficiary?.zipCode ?? ''}
      countryValue={idleBeneficiary.birthCountry}
      socialSecurityNumberValue={''}
      socialRegimeValue={socialRegimeValue}
      onClose={onClose}
      onSubmit={onSubmit}
      onFirstNameUpdate={handleChangeFirstName}
      onBirthdateUpdate={handleChangeBirthdate}
      onPostCodeUpdate={handleChangePostCode}
      onChangeSocialRegimeUpdate={handleChangeSocialRegime}
      regimes={regimes}
      selectedRegime={[]}
      onDeleteMember={onDelete}
      canDelete={canDelete}
      canSubmit={canSubmit}
      /**
       * Child properties
       */
      isChild={isChild}
      isDisabledAdult={isDisabledAdult}
      onChangeIsDisabledAdult={handleOnIsDisableAdultChanged}
      linkedFamilyMember={linkedFamilyMember}
      onChangeLinkedFamilyMemberUpdate={handleChangeLinkedFamilyMember}
    />
  )
})

const avatarPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: kiss,
  CHILD: child,
  OTHER: personWhiteHair,
}

const namingPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: 'Votre conjoint',
  CHILD: 'Votre enfant',
  OTHER: 'Une personne à charge',
}

const familyMembers = (
  setBeneficiaryType: (value: BeneficiaryMemberModalType) => void,
  showModal: () => void,
  alreadyHasPartner: boolean,
) => {
  return mutaBeneficiaryTypes
    .filter((type) => !(type === 'PARTNER' && alreadyHasPartner))
    .map((type) => {
      return {
        emoji: avatarPerBeneficiaryType[type],
        title: namingPerBeneficiaryType[type],
        onClick: () => {
          setBeneficiaryType({ type: 'create', beneficiaryType: type })
          showModal()
        },
      }
    })
}
