import {
  isSubscriptionValidationStatus,
  subscriptionDocumentDimensionByType,
  type SubscriptionDocumentType,
  type SubscriptionDocumentValidationStatus,
} from '@orus.eu/dimensions'
import {
  Chip,
  RowButtonV2,
  RowContainerV2,
  downloadFile,
  useDialogVisibility,
  useLanguage,
  type LoadedFile,
  type UploadedFile,
  type UploaderStorageBackend,
} from '@orus.eu/pharaoh'
import {
  SubscriptionDocumentUploadDialog,
  subscriptionDocumentBackgroundColor,
  subscriptionDocumentStatusText,
  subscriptionDocumentTextColor,
  subscriptionFunnelDocumentStatusText,
} from '@orus.eu/pharaoh/src/components/features/document-collection'
import { memo, useCallback, useEffect, useState } from 'react'
import { getMySubscriptionDocumentLink, getSubscriptionDocumentLink } from '../../../../../lib/download-links'
import { uploadFileFromBuffer } from '../../../../../lib/upload'
import { getUploadMySubscriptionFilePath, getUploadSubscriptionFilePath } from '../../../../../lib/upload-paths'
import type { StateProxy } from '../../subscription-v2-props'

type DocumentListProps = {
  stateProxy: StateProxy
  subscriptionId: string
  isBackoffice?: boolean
  neededDocuments: SubscriptionDocumentType[]
}

type DialogProps = {
  documentType: SubscriptionDocumentType
  storageBackend: UploaderStorageBackend
  onClose: () => void
  onValidate: () => void
  currentStatus: SubscriptionDocumentValidationStatus
  onStatusChange: (newStatus: string | null) => void
  isBackoffice?: boolean
}

export const DocumentList = memo<DocumentListProps>(function DocumentList({
  stateProxy,
  subscriptionId,
  isBackoffice,
  neededDocuments,
}) {
  const language = useLanguage()
  const {
    hide: hideDocumentUploadDialog,
    show: showDocumentUploadDialog,
    visible: isDocumentUploadDialogVisible,
  } = useDialogVisibility('add-document')

  const [dialogProps, setDialogProps] = useState<DialogProps | undefined>()

  const [currentFileList, setCurrentFileList] = useState<UploadedFile[]>([])
  const [currentDocumentType, setCurrentDocumentType] = useState<SubscriptionDocumentType | undefined>()
  const [currentStatus, setCurrentStatus] = useState<SubscriptionDocumentValidationStatus | undefined>()
  const [hasSentDocument, setHasSentDocument] = useState<boolean>(false)
  const documentStatusText = isBackoffice ? subscriptionDocumentStatusText : subscriptionFunnelDocumentStatusText

  const getFiles: () => Promise<UploadedFile[]> = useCallback(() => {
    return Promise.resolve(currentFileList)
  }, [currentFileList])

  const downloadUploadedFile: (file: UploadedFile) => Promise<void> = useCallback(
    (file) => {
      if (currentDocumentType) {
        const link = isBackoffice
          ? getSubscriptionDocumentLink(subscriptionId, file.id)
          : getMySubscriptionDocumentLink(subscriptionId, file.id)
        return downloadFile(link, file.name, file.mimeType)
      } else return Promise.reject('No document type selected')
    },
    [currentDocumentType, isBackoffice, subscriptionId],
  )

  const uploadNewFile: (file: LoadedFile) => Promise<UploadedFile> = useCallback(
    (file) => {
      if (currentDocumentType) {
        const path = isBackoffice
          ? getUploadSubscriptionFilePath({ type: currentDocumentType, subscriptionId })
          : getUploadMySubscriptionFilePath({ type: currentDocumentType, subscriptionId })

        return uploadFileFromBuffer(path, file.file).then((newFile) => {
          const uploadedFile = {
            type: 'uploaded',
            name: newFile.fileName,
            id: newFile.fileId,
            uploadTimestamp: newFile.timestamp,
            mimeType: newFile.mimeType,
          } as const
          setCurrentFileList((previousCurrentFileList) =>
            [...previousCurrentFileList, uploadedFile].sort((a, b) => a.name.localeCompare(b.name)),
          )
          setHasSentDocument(true)
          return uploadedFile
        })
      }
      return Promise.reject('No document type selected')
    },
    [currentDocumentType, isBackoffice, subscriptionId],
  )

  const removeFile: (file: UploadedFile) => Promise<void> = useCallback(
    (file) => {
      const fileIndex = currentFileList.findIndex((currentFile) => currentFile.id === file.id)
      if (fileIndex === -1) {
        return Promise.reject('File not found')
      }

      setCurrentFileList((previousCurrentFileList) => {
        const array = Array.from(previousCurrentFileList)
        array.splice(fileIndex, 1)
        return array
      })
      return Promise.resolve()
    },
    [currentFileList],
  )

  const updateFile: (file: UploadedFile) => Promise<void> = useCallback(
    (file) => {
      const fileIndex = currentFileList.findIndex((currentFile) => currentFile.id === file.id)
      if (fileIndex === -1) {
        return Promise.reject('File not found')
      }

      setCurrentFileList((previousCurrentFileList) => {
        const array = Array.from(previousCurrentFileList)
        array[fileIndex] = file
        return array
      })
      return Promise.resolve()
    },
    [currentFileList],
  )

  const handleStatusChange = useCallback((newStatus: string | null) => {
    if (newStatus !== null && isSubscriptionValidationStatus(newStatus)) {
      setCurrentStatus(newStatus)
    }
  }, [])

  const handleClose = useCallback(() => {
    setCurrentDocumentType(undefined)
    setCurrentStatus(undefined)
    setHasSentDocument(false)
    hideDocumentUploadDialog()
  }, [hideDocumentUploadDialog])

  const handleValidate = useCallback(() => {
    if (currentDocumentType && currentStatus) {
      const dimension = subscriptionDocumentDimensionByType[currentDocumentType]
      const value = {
        status: hasSentDocument && !isBackoffice ? 'received' : currentStatus,
        files: currentFileList.map((file) => ({
          name: file.name,
          id: file.id,
          creationTimestamp: file.uploadTimestamp,
          mimeType: file.mimeType,
          description: file.description,
        })),
      }

      if (isBackoffice) {
        stateProxy.write(dimension, value)
      } else {
        stateProxy.writePermanently(dimension, value)
      }
      setCurrentDocumentType(undefined)
      setCurrentStatus(undefined)
      setHasSentDocument(false)
      hideDocumentUploadDialog()
    }
  }, [
    currentDocumentType,
    currentFileList,
    currentStatus,
    hasSentDocument,
    hideDocumentUploadDialog,
    isBackoffice,
    stateProxy,
  ])

  useEffect(() => {
    if (currentDocumentType) {
      const dimension = stateProxy.readRequired(subscriptionDocumentDimensionByType[currentDocumentType])

      setCurrentStatus(dimension.status)
      setCurrentFileList(
        dimension.files.map((file) => ({
          type: 'uploaded',
          name: file.name,
          id: file.id,
          uploadTimestamp: file.creationTimestamp,
          mimeType: file.mimeType,
          description: file.description,
        })),
      )
      showDocumentUploadDialog()
    }
  }, [currentDocumentType, showDocumentUploadDialog, stateProxy])

  useEffect(() => {
    if (currentDocumentType && currentStatus)
      setDialogProps({
        documentType: currentDocumentType,
        storageBackend: {
          getFiles,
          removeFile,
          uploadFile: uploadNewFile,
          downloadFile: downloadUploadedFile,
          updateFile,
        },
        onClose: handleClose,
        onValidate: handleValidate,
        onStatusChange: handleStatusChange,
        currentStatus: currentStatus,
        isBackoffice,
      })
  }, [
    currentDocumentType,
    currentStatus,
    downloadUploadedFile,
    getFiles,
    handleClose,
    handleStatusChange,
    handleValidate,
    isBackoffice,
    removeFile,
    updateFile,
    uploadNewFile,
  ])

  return (
    <>
      <RowContainerV2 noContainedBorder={true}>
        {neededDocuments
          ?.map((documentType) => {
            const dimension = stateProxy.readRequired(subscriptionDocumentDimensionByType[documentType])
            return {
              status: dimension.status,
              displayName: subscriptionDocumentDimensionByType[documentType].displayNames[language],
              documentType,
            }
          })
          .map(({ status, displayName, documentType }, index) => (
            <RowButtonV2
              key={index}
              primaryText={displayName}
              avatarRight={
                <Chip
                  variant="neutral"
                  size="large"
                  textColor={subscriptionDocumentTextColor[status]}
                  backgroundColor={subscriptionDocumentBackgroundColor[status]}
                >
                  {documentStatusText[status]}
                </Chip>
              }
              onClick={() => {
                setCurrentDocumentType(documentType)
              }}
            />
          ))}
      </RowContainerV2>

      {isDocumentUploadDialogVisible && dialogProps && <SubscriptionDocumentUploadDialog {...dialogProps} />}
    </>
  )
})
