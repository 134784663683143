import { css } from '@emotion/react'
import { InputAdornment } from '@mui/material'
import { formatFrenchAddress1Line } from '@orus.eu/address'
import { parseSirenValue, type CompanyIdNumber } from '@orus.eu/company-id-number'
import type { PlaceData, SiretSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  Avatar,
  PersistentNotification,
  RowButtonV2,
  RowContainerV2,
  spacing,
  Spinner,
  TextField,
  TextInputLabelWrapper,
  useDebounce,
} from '@orus.eu/pharaoh'
import type { ActionButton } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { isSuccess } from '@orus.eu/result'
import { parseSiret, type JsonableSiretData } from '@orus.eu/siret'
import { memo, useCallback, useEffect, useMemo, useState, type ChangeEvent, type KeyboardEvent } from 'react'
import { ifStateProxy } from '../if-state-proxy'
import type { SubscriptionElementBlockProps } from '../subscription-v2-props'

export const SiretSearchSubscriptionUiElementBlock = ifStateProxy<SiretSearchSubscriptionUiElement>(
  function SiretSearchSubscriptionUiElementBlock({
    handleSubmit,
    uiElement,
    stateProxy,
    synchronizing,
    isLoadingWhileTryCompleteStep,
    context,
  }) {
    /**
     * The query as typed by the user
     */
    const [rawQuery, setRawQuery] = useState(stateProxy.readRequired(uiElement.dimensions.placeSearchQuery))
    const [debouncedRawQuery] = useDebounce(rawQuery, 1000)

    /**
     * The query that is stored in the state
     */
    const storedQuery = stateProxy.readRequired(uiElement.dimensions.placeSearchQuery)

    const [displayResult, setDisplayResult] = useState(context === 'backoffice' ? false : true)

    const writePlaceSearchQuery = stateProxy.useWrite(uiElement.dimensions.placeSearchQuery)

    const placesResult = stateProxy.read(uiElement.dimensions.placeSearchResults)
    const places = placesResult && isSuccess(placesResult) ? placesResult.output : noPlaces

    const handleQueryChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setRawQuery(event.target.value)
    }, [])

    useEffect(() => {
      writePlaceSearchQuery(debouncedRawQuery)
      if (!displayResult) {
        setDisplayResult(true)
      }
    }, [debouncedRawQuery, displayResult, writePlaceSearchQuery])

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          if (Array.isArray(places) && places.length === 1) {
            handleSubmit(undefined, buildPlaceSelectionUpdate(places[0], uiElement))
          }
        }
      },
      [handleSubmit, places, uiElement],
    )

    if (isLoadingWhileTryCompleteStep)
      return (
        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: ${spacing[50]};
          `}
        >
          <Spinner size="70" />
        </div>
      )

    return (
      <>
        <div
          css={
            context === 'backoffice'
              ? css`
                  display: flex;
                  flex-direction: column;
                `
              : undefined
          }
        >
          <ResultElement
            context={context}
            displayResult={displayResult}
            handleKeyDown={handleKeyDown}
            handleQueryChange={handleQueryChange}
            handleSubmit={handleSubmit}
            places={places}
            rawQuery={rawQuery}
            storedQuery={storedQuery}
            synchronizing={synchronizing}
            uiElement={uiElement}
          />
        </div>
        {context !== 'backoffice' ? (
          <RowContainerV2
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            <RowButtonV2 onClick={handleSubmit} primaryText={uiElement.cantFindText} />
          </RowContainerV2>
        ) : (
          <></>
        )}
      </>
    )
  },
)

type ResultElementProps = {
  uiElement: SiretSearchSubscriptionUiElement
  synchronizing: boolean
  storedQuery: string
  rawQuery: string
  places: PlaceData[]
  displayResult: boolean
  handleQueryChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleKeyDown: (event: KeyboardEvent) => void
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
  context: 'backoffice' | 'selfonboarding'
}

const ResultElement = memo<ResultElementProps>(function ResultElement({
  uiElement,
  synchronizing,
  storedQuery,
  rawQuery,
  places,
  displayResult,
  handleQueryChange,
  handleKeyDown,
  handleSubmit,
  context,
}) {
  return (
    <>
      <TextInputLabelWrapper label={uiElement.searchText} required={uiElement.required}>
        <TextField
          size={context === 'backoffice' ? 'small' : 'large'}
          InputProps={inputProps}
          value={rawQuery}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          error={uiElement.highlight}
        />
      </TextInputLabelWrapper>
      {rawQuery.trim().length > 0 && displayResult ? (
        <ResultsArea
          loading={synchronizing || rawQuery !== storedQuery}
          places={places}
          handleSubmit={handleSubmit}
          uiElement={uiElement}
          hideActionButton={context === 'backoffice'}
        />
      ) : (
        <></>
      )}
    </>
  )
})

const SearchIcon = memo(function SearchIcon() {
  return (
    <div
      css={css`
        width: ${spacing[60]};
      `}
    >
      <Avatar icon="magnifying-glass-regular" size="30" />
    </div>
  )
})

const inputProps = {
  startAdornment: <InputAdornment position="start" component={SearchIcon} />,
}

type ResultsAreaProps = {
  loading: boolean
  places: PlaceData[]
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
  uiElement: SiretSearchSubscriptionUiElement
  hideActionButton?: boolean
}

const ResultsArea = memo<ResultsAreaProps>(function ResultsArea({
  loading,
  places,
  handleSubmit,
  uiElement,
  hideActionButton,
}) {
  const cantFindActionButton: ActionButton = useMemo(
    () => ({ label: 'Ajouter mon établissement', onClick: handleSubmit }),
    [handleSubmit],
  )

  if (loading) return <PlaceLoadingPlaceholder />

  if (places.length === 0) {
    return (
      <PersistentNotification
        css={css`
          margin-top: ${spacing[70]};
        `}
        variant="info"
        title="Établissement introuvable ?"
        actionButton={hideActionButton ? undefined : cantFindActionButton}
      >
        Si vous venez de créer votre établissement, il est possible qu’il ne soit pas encore référencé. Dans ce cas,
        vous pouvez l’ajouter à la main.
      </PersistentNotification>
    )
  }

  return (
    <RowContainerV2
      css={css`
        margin-top: ${spacing[70]};
      `}
    >
      {places.map((place) => (
        <ResultButton key={place.siret} place={place} handleSubmit={handleSubmit} uiElement={uiElement} />
      ))}
    </RowContainerV2>
  )
})

type ResultButtonProps = {
  place: PlaceData
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
  uiElement: SiretSearchSubscriptionUiElement
}

const ResultButton = memo<ResultButtonProps>(function ResultButton({ place, handleSubmit, uiElement }) {
  const handleClick = useCallback(() => {
    const updateOnPlaceSelected = buildPlaceSelectionUpdate(place, uiElement)
    handleSubmit(undefined, updateOnPlaceSelected)
  }, [handleSubmit, place, uiElement])

  return (
    <RowButtonV2
      key={place.siret}
      onClick={handleClick}
      primaryText={place.name ?? place.companyName ?? place.siret}
      secondaryText={place.siret}
      tertiaryText={formatFrenchAddress1Line(place)}
    />
  )
})

function buildPlaceSelectionUpdate(place: PlaceData, uiElement: SiretSearchSubscriptionUiElement) {
  const siret = parseSiret(place.siret)
  const siretData: JsonableSiretData | undefined = siret ? { type: 'provided', siret } : undefined
  const siren = parseSirenValue(place.siret.substring(0, 9))
  const companyIdNumber: CompanyIdNumber | undefined = siren ? { type: 'inferred', siren } : undefined
  const placeName = place.name || undefined
  const companyName = place.companyName || undefined
  const activityAddressStreet = place.street || undefined
  const activityAddressPostCode = place.postCode || undefined
  const activityAddressCity = place.city || undefined
  const companyCreateDate = place.registrationDate
  return {
    [uiElement.dimensions.siret.name]: siretData,
    [uiElement.dimensions.companyIdNumber.name]: companyIdNumber,
    [uiElement.dimensions.placeName.name]: placeName,
    [uiElement.dimensions.companyName.name]: companyName,
    [uiElement.dimensions.activityAddressStreet.name]: activityAddressStreet,
    [uiElement.dimensions.activityAddressPostCode.name]: activityAddressPostCode,
    [uiElement.dimensions.activityAddressCity.name]: activityAddressCity,
    [uiElement.dimensions.companyCreationDate.name]: companyCreateDate,
  }
}

const noPlaces: PlaceData[] = []

const PlaceLoadingPlaceholder = memo(function PlaceLoadingPlaceholder() {
  return (
    <div
      css={css`
        margin-top: ${spacing[70]};
        display: flex;
        justify-content: center;
      `}
    >
      <Spinner size="50" />
    </div>
  )
})
