import { X0 } from '@orus.eu/amount'
import { getDiscountRate, type CheckoutSubscriptionUiElement, type ProductAttributes } from '@orus.eu/dimensions'
import type { CustomerData } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { useCallback, useMemo } from 'react'
import { useCheckoutPagePhase } from '../../subscription/checkout/checkout-util'
import { CheckoutPageContent } from '../../subscription/checkout/shared-subscription-checkout-page'
import { ifStateProxy } from '../if-state-proxy'

export const CheckoutSubscriptionUiElementBlock = ifStateProxy<CheckoutSubscriptionUiElement>(
  function CheckoutSubscriptionUiElementBlock({ uiElement, stateProxy, subscriptionId }) {
    const phase = useCheckoutPagePhase()

    const navigate = useNavigate()

    const discount = stateProxy.read(uiElement.dimensions.discount)
    const discountRate = getDiscountRate(discount) ?? X0

    const customerData: CustomerData = {
      firstName: stateProxy.readRequired(uiElement.dimensions.firstName),
      lastName: stateProxy.readRequired(uiElement.dimensions.lastName),
      email: stateProxy.readRequired(uiElement.dimensions.email),
      address: stateProxy.readRequired(uiElement.dimensions.address),
      city: stateProxy.readRequired(uiElement.dimensions.city),
      postalCode: stateProxy.readRequired(uiElement.dimensions.postCode),
    }

    const products = stateProxy.readRequired(uiElement.dimensions.offerInformation).products
    const productsAttributes = useMemo<ProductAttributes[]>(
      () =>
        products.map((product) => ({
          productName: product.name,
          attributes: product.attributes,
        })),
      [products],
    )

    const goBackToQuoteStep = useCallback(() => {
      void navigate({
        to: '/subscribe/$subscriptionId/$stepId',
        params: {
          subscriptionId,
          stepId: 'quote',
        },
      })
    }, [navigate, subscriptionId])

    const oneTime = stateProxy.read(uiElement.dimensions.rcdaQuote)?.oneTime

    return (
      <CheckoutPageContent
        goBackToQuoteStep={goBackToQuoteStep}
        phase={phase}
        subscriptionId={subscriptionId}
        dueDate={stateProxy.readRequired(uiElement.dimensions.expectedFirstPaymentDate)}
        quote={stateProxy.readRequired(uiElement.dimensions.quote)}
        generalTermsGroups={stateProxy.readRequired(uiElement.dimensions.generalTermsGroups)}
        commitment={stateProxy.readRequired(uiElement.dimensions.commitment)}
        paymentRecurrence={stateProxy.readRequired(uiElement.dimensions.paymentRecurrence)}
        forbiddenMonthlyPayment={stateProxy.readRequired(uiElement.dimensions.forbiddenMonthlyPayment)}
        discount={discountRate}
        productsAttributes={productsAttributes}
        startDate={stateProxy.readRequired(uiElement.dimensions.startDate)}
        yearlyCommitmentHasDiscount={stateProxy.readRequired(uiElement.dimensions.yearlyCommitmentHasDiscount)}
        contractColumnWidth="100%"
        riskCarrierProducts={stateProxy.readRequired(uiElement.dimensions.riskCarrierProducts)}
        historyTakeoverPrice={oneTime ? oneTime.final.options.historyTakeover?.premium.total : undefined}
        partnerApplicationFee={stateProxy.readRequired(uiElement.dimensions.quote).partnerApplicationPremium}
        customerData={customerData}
      />
    )
  },
)
