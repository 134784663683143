import { type Amount } from '@orus.eu/amount'
import type { PartnerApplicationFeeUiElement } from '@orus.eu/dimensions'
import { useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { useCallback, useState } from 'react'
import { amountCustomInputProps, amountMapperWithNone } from '../../../../lib/amount'
import { ifStateProxy } from '../if-state-proxy'

export const PartnerApplicationFeeUiElementBlock = ifStateProxy<PartnerApplicationFeeUiElement>(
  function PartnerManagementFeeUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const dimensionValue = stateProxy.read(dimension)
    const [fee, setFee] = useState<Amount | 'none' | undefined>(dimensionValue === 'none' ? undefined : dimensionValue)

    const handleChange = useCallback(
      (value: Amount | 'none' | undefined) => {
        setFee(value)
        stateProxy.write(uiElement.dimension, value ?? 'none')
      },
      [stateProxy, uiElement.dimension],
    )

    return (
      <ValidatedTextFieldFormField
        size={'small'}
        label={'Montant'}
        value={fee}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={amountMapperWithNone}
        infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
        InputProps={amountCustomInputProps}
      />
    )
  },
)
