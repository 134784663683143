import { css } from '@emotion/react'
import { financialRateToNumber, newFinancialRate, rateToPercentString } from '@orus.eu/amount'
import type { PartnerManagementFeeUiElement } from '@orus.eu/dimensions'
import { FlexColumn, Slider, spacing, Text } from '@orus.eu/pharaoh'
import { useCallback, useMemo, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const PartnerManagementFeeUiElementBlock = ifStateProxy<PartnerManagementFeeUiElement>(
  function PartnerManagementFeeUiElementBlock({ uiElement, stateProxy }) {
    const dimensionValue = stateProxy.read(uiElement.dimension)
    const [feeRateNumber, setFeeRateNumber] = useState<number>(
      dimensionValue === 'none' ? 0 : dimensionValue ? financialRateToNumber(dimensionValue) * 100 : 0,
    )

    const feeRate = useMemo(() => {
      if (dimensionValue === 'none') {
        return newFinancialRate(0)
      }
      if (dimensionValue) {
        return dimensionValue
      }
      return newFinancialRate(0)
    }, [dimensionValue])

    const handleChange = useCallback(
      (value: number) => {
        setFeeRateNumber(value)
        stateProxy.write(uiElement.dimension, newFinancialRate(value / 100))
      },
      [stateProxy, uiElement.dimension],
    )

    return (
      <FlexColumn
        css={css`
          align-items: center;
          margin: 0;
          gap: ${spacing[10]};
        `}
      >
        <Slider min={0} max={20} value={feeRateNumber} onChange={handleChange} />
        <Text>{rateToPercentString(feeRate, 0, true)}</Text>
      </FlexColumn>
    )
  },
)
