import {
  isFinancialRateInRange,
  parseFinancialRateFromPercent,
  rateToPercentString,
  type FinancialRate,
  type FinancialRateConfiguration,
} from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import type { ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { PercentFormatter } from '../components/molecules/validated-text-field'

export const financialRateCustomInputProps = { inputComponent: PercentFormatter }

const rateToPercentStringFormatOptions = [0, false] as const

export function getFinancialRateMapper(config: FinancialRateConfiguration): ValidatedTypeMapper<FinancialRate> {
  return {
    inputType: 'text',
    format: (value) => rateToPercentString(value, ...rateToPercentStringFormatOptions),
    formatPlaceholder: (value) => `%${nbsp}${rateToPercentString(value, ...rateToPercentStringFormatOptions)}`,
    parse: (text) => {
      const financialRate = parseFinancialRateFromPercent(text)
      if (financialRate) {
        if (isFinancialRateInRange(financialRate, config)) return success(financialRate)
        else return failure('Saisir un pourcentage valide')
      }
      return failure('Saisir un pourcentage valide')
    },
  }
}
