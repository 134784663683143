import { css } from '@emotion/react'
import { type QuotePageSubtitleSubscriptionUiElement } from '@orus.eu/dimensions'
import { spacing, Text } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const QuotePageSubtitleElementBlock = ifStateProxy<QuotePageSubtitleSubscriptionUiElement>(
  function QuotePageSubtitleElementBlock({ uiElement, stateProxy }) {
    return (
      <Text
        variant="body1"
        css={css`
          margin-top: ${spacing[30]};
        `}
      >
        {stateProxy.readRequired(uiElement.dimensions.activity).displayName}
      </Text>
    )
  },
)
