import { addAmounts, amountToString, zeroAmount, type Amount, type AmountToStringOptions } from '@orus.eu/amount'
import type { ProductAttributes } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { capitalizeFirstLetter } from '../../../lib/format'

export function getExplanationsAboveSubscriptionButton(productsAttributes: ProductAttributes[]): string[] {
  const allProductsAttributes = productsAttributes.flatMap(({ attributes }) => attributes)

  const oneTimeExtra: Record<string, Amount> = {}

  const yearlyExtra: Record<string, Amount> = {}

  for (const productAttributeDescription of allProductsAttributes) {
    if (productAttributeDescription.type !== 'amount') continue

    if (productAttributeDescription.oneTimeExtra) {
      if (!oneTimeExtra[productAttributeDescription.oneTimeExtra.name]) {
        oneTimeExtra[productAttributeDescription.oneTimeExtra.name] = zeroAmount
      }
      oneTimeExtra[productAttributeDescription.oneTimeExtra.name] = addAmounts(
        oneTimeExtra[productAttributeDescription.oneTimeExtra.name],
        productAttributeDescription.value,
      )
    }

    if (productAttributeDescription.yearlyExtra) {
      if (!yearlyExtra[productAttributeDescription.yearlyExtra.name]) {
        yearlyExtra[productAttributeDescription.yearlyExtra.name] = zeroAmount
      }
      yearlyExtra[productAttributeDescription.yearlyExtra.name] = addAmounts(
        yearlyExtra[productAttributeDescription.yearlyExtra.name],
        productAttributeDescription.value,
      )
    }
  }

  const explanations: string[] = []

  const yearlyExtraEntries = Object.entries<Amount>(yearlyExtra).map(([name, amount]) => ({ name, amount }))

  switch (yearlyExtraEntries.length) {
    case 0:
      break
    case 1:
      explanations.push(
        `${capitalizeFirstLetter(yearlyExtraEntries[0].name)} de ${amountToString(yearlyExtraEntries[0].amount, amountToStringOptions)} incluse dans la 1re mensualité, puis une fois par an.`,
      )
      break
    default:
      throw new TechnicalError('Unsupported number of yearly extra')
  }

  const oneTimeExtraEntries = Object.entries<Amount>(oneTimeExtra).map(([name, amount]) => ({ name, amount }))

  switch (oneTimeExtraEntries.length) {
    case 0:
      break
    case 1:
      explanations.push(
        `${capitalizeFirstLetter(oneTimeExtraEntries[0].name)} de ${amountToString(oneTimeExtraEntries[0].amount, amountToStringOptions)} incluse uniquement dans le 1er paiement.`,
      )
      break
    default:
      throw new TechnicalError('Unsupported number of one time extra')
  }

  return explanations
}

const amountToStringOptions: AmountToStringOptions = { addCurrency: true }
