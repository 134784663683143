import {
  discountCodeLabels,
  discountDefinitionPerCode,
  getDiscountByCode,
  type DiscountUiElement,
} from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { DropdownFormField, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const DiscountUiElementBlock = ifStateProxy<DiscountUiElement>(function DiscountUiElementBlock({
  uiElement,
  stateProxy,
}) {
  const language = useLanguage()
  const dimensionValue = stateProxy.read(uiElement.dimension)

  const valueString = dimensionValue && dimensionValue !== 'none' ? dimensionValue.code : null

  const handleChange = useCallback(
    (stringValue?: string | null) => {
      const newValue = stringValue ? getDiscountByCode(stringValue) : 'none'

      if (!newValue) {
        throw new TechnicalError('Unexpected value received for discount from Dropdown component', {
          context: { stringValue },
        })
      }

      stateProxy.write(uiElement.dimension, newValue)
    },
    [stateProxy, uiElement.dimension],
  )

  const availableDiscounts = useMemo(
    () =>
      Object.entries(discountDefinitionPerCode)
        .filter(([code, definition]) => definition.available || code === valueString)
        .map(([code]) => code),
    [valueString],
  )

  return (
    <DropdownFormField
      size="small"
      label={uiElement.dimension.displayNames[language]}
      value={valueString}
      placeholder={uiElement.dimension.placeholders ? uiElement.dimension.placeholders[language] : undefined}
      onChange={handleChange}
      values={availableDiscounts}
      labels={discountCodeLabels}
      nullLabel="Aucun discount"
    />
  )
})
