import styled from '@emotion/styled'
import { memo, type ComponentProps, type ReactNode } from 'react'

import { spacing } from '../../../../foundation'
import { Dialog, DocumentPreview, DocumentPreviewContext, useDocumentViewer } from '../../../../patterns'
import { Avatar, FlexRow } from '../../../atoms'
import { Button } from '../../../button/button'

const TopBarActionButtons = function TopBarActionButtons({ onClose }: { onClose: () => void }) {
  const { nextDocument, previousDocument, downloadDocument } = useDocumentViewer()

  return (
    <FlexRow gap={spacing[30]}>
      <Button variant="secondary" icon="angle-up-regular" onClick={nextDocument} size="small" />
      <Button variant="secondary" icon="angle-down-regular" onClick={previousDocument} size="small" />
      <Button variant="secondary" icon="arrow-down-to-line-regular" onClick={downloadDocument} size="small" />
      <Button variant="secondary" icon="xmark-regular" onClick={onClose} size="small" />
    </FlexRow>
  )
}

export const DocumentViewerDialog = memo<{
  /** The title that shall be displayed on the dialog */
  title: string
  /** the list of documents to be displayed, it is of the form {uri: string, fileType?: string} */
  documents: ComponentProps<typeof DocumentPreview>['documents']
  /** Sets the state of the currently active document, so it can be managed externally from the docviewer */
  setActiveDocument?: ComponentProps<typeof DocumentPreviewContext>['setActiveDocument']
  /** Gets the state of the currently active document. It's an initial state if no setter is given */
  activeDocument?: ComponentProps<typeof DocumentPreviewContext>['activeDocument']
  /** Action to be executed on close of the modal */
  onClose: () => void
  onReviewFinished: () => void
  children: ReactNode
}>(function DocumentViewerDialog({ title, documents, onClose, onReviewFinished, children, ...activeDocumentContext }) {
  return (
    <DocumentPreviewContext {...activeDocumentContext}>
      <Dialog
        isBackoffice
        title={title}
        topRightButtonGroup={<TopBarActionButtons onClose={onClose} />}
        size="xlarge"
        primaryActionLabel="Lancer la séquence"
        secondaryActionLabel="Fermer"
        onPrimaryAction={onReviewFinished}
        primaryActionAvatar={<Avatar icon="paper-plane-regular" />}
        primaryActionAvatarPosition="left"
        onSecondaryAction={onClose}
        onClose={onClose}
      >
        <DialogRow>
          <DocumentPreview documents={documents} />
          {children}
        </DialogRow>
      </Dialog>
    </DocumentPreviewContext>
  )
})

const DialogRow = styled(FlexRow)`
  min-width: 100%;
`
