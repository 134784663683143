import {
  type BrokerDiscountUiElement,
  brokerDiscountCodeLabels,
  brokerDiscountCodes,
  brokerDiscountRatePerCode,
} from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { Select, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const BrokerDiscountUiElementBlock = ifStateProxy<BrokerDiscountUiElement>(
  function BrokerDiscountUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const dimensionValue = stateProxy.readRequired(dimension)
    const [discountCode, setDiscountCode] = useState<string | null>(
      dimensionValue === 'none' ? null : dimensionValue.code,
    )

    const handleChange = useCallback(
      (stringValue?: string | null) => {
        if (!stringValue || stringValue === 'none') {
          stateProxy.write(dimension, 'none')
          setDiscountCode(null)
          return
        }

        const discountCode = brokerDiscountCodes.find((discount) => discount === stringValue)
        if (!discountCode) {
          throw new TechnicalError('Unexpected value received for broker discount', {
            context: { stringValue },
          })
        }

        stateProxy.write(dimension, { code: discountCode, rate: brokerDiscountRatePerCode[discountCode] })
        setDiscountCode(discountCode)
      },
      [dimension, stateProxy],
    )

    return (
      <Select
        size="small"
        value={discountCode}
        onChange={handleChange}
        values={brokerDiscountCodes}
        labels={brokerDiscountCodeLabels}
        nullLabel={dimension.placeholders ? dimension.placeholders[language] : undefined}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
      />
    )
  },
)
