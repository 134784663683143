import { css } from '@emotion/react'
import { subscriptionDocumentDimensionByType, type SubscriptionDocumentType } from '@orus.eu/dimensions'
import { memo, useCallback, useState, type ChangeEvent } from 'react'
import { colorTokens, spacing, useLanguage } from '../../../..'
import { timeAgo } from '../../../../lib/time'
import { Dialog } from '../../../../patterns'
import { Divider, Text } from '../../../atoms'
import { Textarea } from '../../../atoms/textarea'
import type { SubscriptionDocumentNoteRecord, SubscriptionDocumentNoteValue } from './types'

export type SubscriptionDocumentNoteDialogProps = {
  globalOverviewNoteValue: string
  onGlobalOverviewNoteChange: (value: string) => void
  subscriptionDocumentNoteValueRecord: SubscriptionDocumentNoteRecord
  onDocumentNoteChange: (note: SubscriptionDocumentNoteValue, documentType: SubscriptionDocumentType) => void
  onClose: () => void
}

export const SubscriptionDocumentNoteDialog = memo<SubscriptionDocumentNoteDialogProps>(
  function SubscriptionDocumentNoteDialog({
    globalOverviewNoteValue,
    onGlobalOverviewNoteChange,
    subscriptionDocumentNoteValueRecord,
    onDocumentNoteChange,
    onClose,
  }) {
    const language = useLanguage()
    const [localGlobalOverviewNoteValue, setLocalGlobalOverviewNoteValue] = useState(globalOverviewNoteValue)
    const [localSubscriptionDocumentNoteValueRecord, setLocalSubscriptionDocumentNoteValueRecord] = useState(
      subscriptionDocumentNoteValueRecord,
    )

    const handleLocalGlobalOverviewNoteChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
      setLocalGlobalOverviewNoteValue(event.target.value)
    }, [])

    const handleGlobalOverviewNoteBlur = useCallback(() => {
      onGlobalOverviewNoteChange(localGlobalOverviewNoteValue)
    }, [localGlobalOverviewNoteValue, onGlobalOverviewNoteChange])

    const handleLocalDocumentNoteChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>, documentType: SubscriptionDocumentType) => {
        setLocalSubscriptionDocumentNoteValueRecord((previous) => {
          return {
            ...previous,
            [documentType]: {
              value: event.target.value,
              valueTimestamp: Date.now(),
            },
          }
        })
      },
      [],
    )

    const handleDocumentNoteBlur = useCallback(
      (documentType: SubscriptionDocumentType) => {
        onDocumentNoteChange(localSubscriptionDocumentNoteValueRecord[documentType], documentType)
      },
      [localSubscriptionDocumentNoteValueRecord, onDocumentNoteChange],
    )

    return (
      <Dialog
        isBackoffice
        onClose={onClose}
        size="large"
        title="Gérer les notes"
        onSecondaryAction={onClose}
        secondaryActionLabel="Fermer"
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            min-width: 100%;
            gap: ${spacing['70']};
          `}
        >
          <div>
            <Textarea
              label="Overview global"
              size="small"
              placeholder="Saisir une note globale"
              value={localGlobalOverviewNoteValue}
              onBlur={handleGlobalOverviewNoteBlur}
              onChange={handleLocalGlobalOverviewNoteChange}
              minRows={1}
              maxRows={15}
            />
          </div>

          <Divider orientation="horizontal" />

          {Object.entries(localSubscriptionDocumentNoteValueRecord).map(
            ([documentTypeKey, { value, valueTimestamp }]) => {
              const documentType = documentTypeKey as SubscriptionDocumentType
              return (
                <div key={subscriptionDocumentDimensionByType[documentType].displayNames[language]}>
                  <Textarea
                    label={subscriptionDocumentDimensionByType[documentType].displayNames[language]}
                    size="small"
                    placeholder="Saisir une note"
                    value={value ?? ''}
                    onChange={(e) => handleLocalDocumentNoteChange(e, documentType)}
                    onBlur={() => handleDocumentNoteBlur(documentType)}
                    minRows={1}
                    maxRows={5}
                  />

                  {value && valueTimestamp ? (
                    <Text
                      variant="caption"
                      color={colorTokens['color-text-base-basic']}
                      css={css`
                        margin-top: ${spacing['20']};
                      `}
                    >
                      {timeAgo(valueTimestamp)}
                    </Text>
                  ) : null}
                </div>
              )
            },
          )}
        </div>
      </Dialog>
    )
  },
)
