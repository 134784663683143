import type { IntegerSubscriptionUiElement } from '@orus.eu/dimensions'
import { useLanguage, ValidatedTextFieldFormField, type ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { failure, success } from '@orus.eu/result'
import { validateInteger } from '../../../../lib/validation'
import { ifStateProxy } from '../if-state-proxy'

export const IntegerSubscriptionUiElementBlock = ifStateProxy<IntegerSubscriptionUiElement>(
  function IntegerSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)

    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        label={dimension.displayNames[language]}
        value={value}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={mapper}
        infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
        aria-label={dimension.ariaLabels ? dimension.ariaLabels[language] : undefined}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)

const mapper: ValidatedTypeMapper<number> = {
  inputType: 'number',
  format: (value) => value.toString(),
  formatPlaceholder: (value) => value.toString(),
  parse: (text) => {
    const result = validateInteger(text)
    return result.ok ? success(result.value) : failure(result.hint)
  },
}
