import { css } from '@emotion/react'
import { Radio } from '@mui/material'
import { amountToString, newAmount } from '@orus.eu/amount'
import type {
  ChoiceSubscriptionUiElement,
  TypeOfDimension,
  mrphFormulaDimension,
  mrphOccupationStatusDimension,
  mrpwOccupationStatusDimension,
} from '@orus.eu/dimensions'
import {
  CheckboxContainer,
  Chip,
  ChoiceGrid,
  DropdownFormField,
  RowButtonV2,
  RowContainerV2,
  Text,
  TextInputLabelWrapper,
  borderRadius,
  colors,
  spacing,
  useCrash,
  useLanguage,
  useScreenType,
  type ChoiceGridItemAvatarProps,
} from '@orus.eu/pharaoh'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { RowContainerWithRadio } from '@orus.eu/pharaoh/src/components/molecules/row-container-with-radio'
import { isFailure, success } from '@orus.eu/result'
import { memo, useCallback, useEffect, useMemo, useState, type ChangeEvent, type MouseEvent } from 'react'
import { assert } from '../../../../lib/errors'
import { ifStateProxy } from '../if-state-proxy'
import type { StateProxy, SubscriptionElementBlockProps } from '../subscription-v2-props'

const isFalseyValue = (currentValue: string | number | undefined) => [0, '0', undefined].includes(currentValue)
const findFirstFalseyValue = (options: readonly string[]) => options.find(isFalseyValue)
const findFirstTruthyValue = (options: readonly string[]) => options.find((option) => !isFalseyValue(option))

export const ChoiceSubscriptionUiElementBlock = ifStateProxy<ChoiceSubscriptionUiElement>(
  function ChoiceSubscriptionUiElementBlock(props) {
    const language = useLanguage()
    const { uiElement, stateProxy, context } = props
    const options = useChoiceOptions(stateProxy, uiElement)
    const valueDimension = getValueDimension(uiElement)
    const currentValue = stateProxy.read<number | string>(valueDimension)
    const currentValidValue =
      currentValue == undefined || options.values.includes(currentValue.toString()) ? currentValue : undefined
    const singlePossibleValidValue = options.values.length === 1 ? options.values[0] : undefined

    useEffect(() => {
      if (currentValidValue !== currentValue || (currentValue == undefined && singlePossibleValidValue)) {
        stateProxy.write<string | number>(valueDimension, singlePossibleValidValue)
      }
    }, [currentValue, currentValidValue, stateProxy, valueDimension, singlePossibleValidValue])

    if (context === 'backoffice') {
      return <SelectChoice options={options} {...props} />
    }

    switch (uiElement.variant) {
      case 'checkbox':
        return <CheckBoxChoice options={options} {...props} />
      case 'one-click-submit-rows':
        return (
          <TextInputLabelWrapper
            label={uiElement.label ?? valueDimension.displayNames[language]}
            infoTooltip={valueDimension.hints ? valueDimension.hints[language] : undefined}
          >
            <OneClickSubmitRowsChoice options={options} {...props} />
          </TextInputLabelWrapper>
        )
      case 'one-click-occupation-status-grid':
        return <OneClickSubmitGridChoice options={options} avatarProps={occupationStatusVisualAttributes} {...props} />
      case 'select':
        return <SelectChoice options={options} {...props} />
      case 'radio-button-cards':
        return <RadioButtonCardsChoice options={options} {...props} />
      case 'radio-group-table':
        return <RadioGroupTableChoice options={options} {...props} />
      case 'radio-group-table-with-title':
        return (
          <TextInputLabelWrapper
            label={uiElement.label ?? valueDimension.displayNames[language]}
            infoTooltip={valueDimension.hints ? valueDimension.hints[language] : undefined}
          >
            <RadioGroupTableChoice options={options} {...props} />
          </TextInputLabelWrapper>
        )
    }
  },
)

const CheckBoxChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    stateProxy: StateProxy
  }
>(function CheckBoxChoice({ uiElement, stateProxy, options }) {
  const valueDimension = getValueDimension(uiElement)
  const currentValue = stateProxy.read<number | string>(valueDimension)

  useEffect(() => {
    if (uiElement.variant !== 'checkbox') return
    if (currentValue == undefined && 'initializeValueWhenDisplayed' in uiElement && uiElement.defaultValue) {
      stateProxy.write<string | number>(valueDimension, uiElement.defaultValue)
    }
  }, [uiElement, currentValue, stateProxy, valueDimension])

  if (uiElement.variant !== 'checkbox') return null

  const handleSelected = (newValue: boolean) => {
    stateProxy.write<string | number>(
      valueDimension,
      newValue ? findFirstTruthyValue(options.values) : findFirstFalseyValue(options.values),
    )
  }

  return (
    <CheckboxContainer checked={!isFalseyValue(currentValue)} onChange={handleSelected} size="small">
      {uiElement.label}
    </CheckboxContainer>
  )
})

const RadioGroupTableChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    stateProxy: StateProxy
  }
>(function RadioGroupTableChoice({ uiElement, stateProxy, options }) {
  const valueDimension = getValueDimension(uiElement)

  const items = useMemo(() => {
    return options.values.map((value) => {
      return { title: options.labels[value], tooltip: options.tooltips ? options.tooltips[value] : undefined }
    })
  }, [options])

  const [selectedValue, setSelectedValue] = useState<string>(() => {
    stateProxy.write<string | number>(
      valueDimension,
      Object.entries(options.labels)
        .filter((label) => label[1] === items[0].title)
        .map((label) => label[0])[0],
    )
    return items[0].title
  })

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedValue(value)
      const selectedOptionValue = Object.entries(options.labels)
        .filter((label) => label[1] === value)
        .map((label) => label[0])[0]
      stateProxy.write<string | number>(valueDimension, selectedOptionValue)
    },
    [options.labels, stateProxy, valueDimension],
  )

  return <RowContainerWithRadio items={items} value={selectedValue} handleChange={handleChange} />
})

const RadioButtonCardsChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    stateProxy: StateProxy
  }
>(function RadioButtonCardsChoice({ uiElement, stateProxy, options }) {
  assert(uiElement.variant === 'radio-button-cards', 'Invalid choice variant')
  const valueDimension = getValueDimension(uiElement)

  const selectedValue = stateProxy.read<number | string>(valueDimension) ?? null
  const handleSelected = stateProxy.useWrite<number | string>(valueDimension)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[30]};
      `}
    >
      {options.values.map((value, index) => (
        <RadioButtonCard
          key={value}
          value={value}
          isSelected={value === selectedValue}
          title={radioCardsTexts[value].title}
          description={radioCardsTexts[value].description}
          isRecommended={index === 0}
          onSelected={handleSelected}
        />
      ))}
    </div>
  )
})

type RadioButtonCardProps = {
  value: string
  title: string
  description: string
  isRecommended: boolean
  isSelected: boolean
  onSelected: (value: string) => void
}
const RadioButtonCard = memo<RadioButtonCardProps>(function RadioButtonCard({
  value,
  isSelected,
  title,
  description,
  isRecommended,
  onSelected,
}) {
  const screenType = useScreenType()
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.preventDefault()
      event.stopPropagation()
      if (checked) onSelected(event.target.value)
    },
    [onSelected],
  )
  const handleClick = useCallback(() => {
    onSelected(value)
  }, [onSelected, value])

  return (
    <div
      onClick={handleClick}
      css={css`
        border-radius: ${borderRadius[20]};
        padding: ${spacing[60]} ${spacing[70]};
        border: 1px solid;
        border-color: ${isSelected ? colors.blue[500] : colors.gray[200]};
        background-color: ${isSelected ? colors.blue[100] : colors.white};
        display: flex;
        gap: ${screenType === 'desktop' ? spacing[60] : spacing[30]};
        align-items: center;
        cursor: pointer;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[20]};
        `}
      >
        {screenType === 'mobile' && isRecommended ? (
          <RecommendedChip
            css={css`
              align-self: start;
              margin-bottom: ${spacing[30]};
            `}
          />
        ) : (
          <></>
        )}
        <Text variant="body1Medium">{title}</Text>
        <Text variant="body2">{description}</Text>
      </div>
      {screenType === 'desktop' ? (
        <RecommendedChip
          css={css`
            /* for layout uniformity */
            visibility: ${isRecommended ? 'visible' : 'hidden'};
          `}
        />
      ) : (
        <></>
      )}
      <Radio
        value={value}
        checked={isSelected}
        css={css`
          flex-shrink: 0;
        `}
        color={isSelected ? 'secondary' : undefined}
        onChange={handleChange}
      />
    </div>
  )
})

const radioCardsTexts: Record<string, { title: string; description: string }> = {
  Basique: {
    title: 'Essentiel',
    description:
      'Couverture essentielle pour les très petites surfaces telles que les ateliers ou bureaux individuels.',
  },
  Confort: {
    title: 'Confort',
    description: 'Protection étendue et adaptée pour la plupart des locaux ou bureaux d’entreprise.',
  },
  Premium: {
    title: 'Premium',
    description: 'Meilleure couverture pour les grandes superficies et les grands espaces de travail.',
  },
} satisfies Record<TypeOfDimension<typeof mrphFormulaDimension>, { title: string; description: string }>

const SelectChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    stateProxy: StateProxy
  }
>(function SelectChoice({ uiElement, stateProxy, options, context }) {
  const language = useLanguage()
  const valueDimension = getValueDimension(uiElement)
  const write = stateProxy.useWrite<number | string>(valueDimension)
  const currentValue = stateProxy.read<number | string>(valueDimension)

  useEffect(() => {
    if (uiElement.variant !== 'checkbox') return
    if (currentValue == undefined && uiElement.defaultValue) {
      stateProxy.write<string | number>(valueDimension, uiElement.defaultValue)
    }
  }, [uiElement, currentValue, stateProxy, valueDimension])

  const handleChange = useCallback(
    (newValue: string | null) => {
      if (newValue === null) return write(null)

      const value = valueDimension.type === 'numbers-enum' ? parseInt(newValue) : newValue
      write(value)
    },
    [valueDimension, write],
  )

  return (
    <DropdownFormField
      size={context === 'backoffice' ? 'small' : 'large'}
      label={'hideLabel' in uiElement && uiElement.hideLabel ? undefined : valueDimension.displayNames[language]}
      badge={context === 'backoffice' ? <TagBadge tag={valueDimension.tags?.[0]} /> : <></>}
      value={currentValue?.toString() ?? null}
      placeholder={valueDimension.placeholders ? valueDimension.placeholders[language] : undefined}
      onChange={handleChange}
      values={options.values}
      labels={options.labels}
      infoTooltip={valueDimension.hints ? valueDimension.hints[language] : undefined}
      disabled={options.values.length === 0}
      /**
       * Use the MUI error prop mechanism to show the required state of the field.
       */
      error={uiElement.highlight}
      required={uiElement.required}
    />
  )
})

type ChoiceOptions = {
  values: readonly string[]
  labels: { [key: string]: string }
  tooltips?: { [key: string]: string | undefined }
}

function useChoiceOptions(stateProxy: StateProxy, uiElement: ChoiceSubscriptionUiElement): ChoiceOptions {
  const crash = useCrash()

  return useMemo<ChoiceOptions>(() => {
    if ('dimension' in uiElement) {
      if (uiElement.dimension.type === 'numbers-enum') {
        return {
          values: uiElement.dimension.values.map((value) => value.toString()),
          labels: Object.fromEntries(
            uiElement.dimension.values.map((value) => [
              value.toString(),
              uiElement.amountToStringOptions
                ? amountToString(newAmount(value), { ...uiElement.amountToStringOptions })
                : value.toString(),
            ]),
          ),
        }
      }

      return {
        values: uiElement.dimension.values,
        labels: Object.fromEntries(uiElement.dimension.entries.map((entry) => [entry[0], entry[1].label])),
        tooltips: Object.fromEntries(uiElement.dimension.entries.map((entry) => [entry[0], entry[1].tooltip])),
      }
    }

    if ('allowedValues' in uiElement.dimensions) {
      const values = stateProxy.read(uiElement.dimensions.allowedValues) ?? []
      const labels = Object.fromEntries(values.map((value) => [value, value]))
      return { values, labels }
    }

    const valuesResult = stateProxy.read(uiElement.dimensions.allowedValuesResult) ?? success([])
    if (isFailure(valuesResult)) {
      // ui is down because asyncrhonous dimension failed
      crash({ type: 'temporary-problem' })
      return { values: [], labels: {} }
    }
    const values = valuesResult.output
    const labels = Object.fromEntries(values.map((value) => [value, value]))

    return { values, labels }
  }, [crash, stateProxy, uiElement])
}

function getValueDimension(uiElement: ChoiceSubscriptionUiElement) {
  return 'dimension' in uiElement ? uiElement.dimension : uiElement.dimensions.value
}

const OneClickSubmitRowsChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    stateProxy: StateProxy
  }
>(function OneClickSubmitRowsChoice({ options: { labels, values }, stateProxy, uiElement, handleSubmit }) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const newValue = event.currentTarget.value
      stateProxy.write<number | string>(getValueDimension(uiElement), newValue)
      handleSubmit(undefined, { [getValueDimension(uiElement).name]: newValue })
    },
    [handleSubmit, stateProxy, uiElement],
  )

  return (
    <RowContainerV2>
      {values.map((value) => (
        <RowButtonV2 key={value} onClick={handleClick} value={value} primaryText={labels[value]} />
      ))}
    </RowContainerV2>
  )
})

const occupationStatusVisualAttributes: Record<
  TypeOfDimension<typeof mrpwOccupationStatusDimension> | TypeOfDimension<typeof mrphOccupationStatusDimension>,
  ChoiceGridItemAvatarProps
> = {
  // MRPW
  Locataire: {
    icon: 'house-light',
    backgroundColor: 'periwinkle',
  },
  'Propriétaire Exploitant': {
    icon: 'key-light',
    backgroundColor: 'mindaro',
  },
  Colocataire: {
    icon: 'people-roof-light',
    backgroundColor: 'jasmine',
  },
  Copropriétaire: {
    icon: 'folder-user-regular',
    backgroundColor: 'peach',
  },

  // MRPH
  LOCATAIRE: {
    icon: 'house-light',
    backgroundColor: 'periwinkle',
  },
  'LOCATAIRE AGISSANT POUR LE COMPTE DU PROPRIETAIRE': {
    icon: 'lock-light',
    backgroundColor: 'sky',
  },
  'OCCUPANT A TITRE GRATUIT': {
    icon: 'hand-peace-light',
    backgroundColor: 'jasmine',
  },
  PROPRIETAIRE: {
    icon: 'key-light',
    backgroundColor: 'mindaro',
  },
  'SOUS LOCATAIRE': {
    icon: 'circle-user-light',
    backgroundColor: 'peach',
  },
}

const OneClickSubmitGridChoice = memo<
  SubscriptionElementBlockProps<ChoiceSubscriptionUiElement> & {
    options: ChoiceOptions
    avatarProps: Record<string, ChoiceGridItemAvatarProps>
    stateProxy: StateProxy
  }
>(function OneClickSubmitRowsChoice({ options: { labels, values }, stateProxy, uiElement, handleSubmit, avatarProps }) {
  const handleClick = useCallback(
    (newValue: string) => {
      stateProxy.write<number | string>(getValueDimension(uiElement), newValue)
      handleSubmit(undefined, { [getValueDimension(uiElement).name]: newValue })
    },
    [handleSubmit, stateProxy, uiElement],
  )

  return (
    <div>
      <ChoiceGrid values={values} labels={labels} onItemClicked={handleClick} avatarProps={avatarProps} />
    </div>
  )
})

type RecommendedChipProps = {
  className?: string
}
const RecommendedChip = memo<RecommendedChipProps>(function RecommendedChip({ className }) {
  return (
    <Chip className={className} backgroundColor={colors.blue[200]} size="small">
      Recommandé
    </Chip>
  )
})
