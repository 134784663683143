import {
  allProducts,
  type PartialDimensionnedState,
  type Product,
  type activityDimension,
  type mrphHiscoxActivitiesNamesDimension,
  type mrpwWakamMainActivityDimension,
  type rcdaAxeriaActivityNamesDimension,
  type rcphHiscoxActivitiesNamesDimension,
  type rcphProductLabelDimension,
} from '@orus.eu/dimensions'
import { Button, FlexColumn, LegacyDialog, Text, spacing, useDialogVisibility } from '@orus.eu/pharaoh'
import { memo, useMemo } from 'react'

export type RiskCarrierInfoProps = {
  state: PartialDimensionnedState<
    [
      typeof activityDimension,
      typeof mrphHiscoxActivitiesNamesDimension,
      typeof rcphHiscoxActivitiesNamesDimension,
      typeof rcphProductLabelDimension,
      typeof mrpwWakamMainActivityDimension,
      typeof rcdaAxeriaActivityNamesDimension,
    ]
  >
}

export const ActivityDetailButton = memo<RiskCarrierInfoProps>(function ActivityDetailButton({ state }) {
  const {
    show: showActivityDetailDialog,
    hide: hideActivityDetailDialog,
    visible: isActivityDetailDialogVisible,
  } = useDialogVisibility('activity-detail')

  const openDialog = () => showActivityDetailDialog()

  const namesBlockPropsByProduct: Record<Product, NamesBlockProps> = useMemo(
    () => ({
      rcph: {
        title: `RC Pro Hiscox - ${state.rcphProductLabel}`,
        names: state.rcphHiscoxActivitiesNames,
        show: !!state.rcphProductLabel,
      },
      mrph: {
        title: 'MRP Hiscox',
        names: state.mrphHiscoxActivitiesNames,
        show: true,
      },
      mrpw: {
        title: 'MRP Wakam',
        names: [state.mrpwWakamMainActivity!],
        show: !!state.mrpwWakamMainActivity,
      },
      rcda: {
        title: 'RCD Axeria',
        names: [state.rcdaAxeriaActivityNames!],
        show: !!state.rcdaAxeriaActivityNames,
      },
      muta: {
        title: '',
        names: null,
        show: false,
      },
      'es-rcph': {
        title: '',
        names: null,
        show: !!state.rcphProductLabel,
      },
    }),
    [state],
  )

  return (
    <>
      {state.activity ? (
        <Button size="small" variant="secondary" onClick={openDialog}>
          Détails
        </Button>
      ) : (
        <></>
      )}

      {isActivityDetailDialogVisible ? (
        <LegacyDialog title="Détail des activités" onClose={hideActivityDetailDialog} variant="backoffice">
          <FlexColumn gap={spacing[40]} margin={` ${spacing[50]} 0 0 0`}>
            {allProducts.map((product) => (
              <NamesBlock key={product} {...namesBlockPropsByProduct[product]} />
            ))}
          </FlexColumn>
        </LegacyDialog>
      ) : (
        <></>
      )}
    </>
  )
})

type NamesBlockProps = {
  title: string
  show: boolean
  names?: string[] | null
}

const NamesBlock = memo<NamesBlockProps>(function NamesBlock({ title, names, show }) {
  if (!names || names.length === 0) return <></>
  if (!show) return null

  return (
    <FlexColumn margin="0" gap={spacing[10]}>
      <Text variant="body2Medium">{title}</Text>
      <Text variant="body2" element="span">
        <ul>
          {names.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </Text>
    </FlexColumn>
  )
})
