import { css } from '@emotion/react'
import { amountToNumber } from '@orus.eu/amount'
import { calendarDateToString, PARIS } from '@orus.eu/calendar-date'
import {
  rcdaOptionHistoryTakeoverYearsLabels,
  rcdaOptionHistoryTakeoverYearsValues,
  type ProductInformation,
  type QuotePageBodySubscriptionUiElement,
} from '@orus.eu/dimensions'
import { DropdownWithDescription, spacing, Text, useLanguage } from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'
import { sendMessage } from '../../../../../lib/tracking/tracking'
import type { StateProxy } from '../../subscription-v2-props'
import { historyTakeoverOptionConfiguration } from './history-takeover-option-configuration'
import { ProductOptionListItem } from './product-options-list'

type RcdaHistoryTakeoverProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  productInformation: ProductInformation
  stateProxy: StateProxy
  subscriptionId: string
}
export const RcdaHistoryTakeoverSelector = memo<RcdaHistoryTakeoverProps>(function RcdaHistoryTakeoverSelector({
  uiElement,
  stateProxy,
  subscriptionId,
  productInformation,
}) {
  const historyTakeoverOption = productInformation.optionalGuarantees.find(
    (option) => option.type === 'HISTORY_TAKEOVER',
  )

  if (!historyTakeoverOption) {
    // history takeover is unavailable
    return <></>
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
    >
      <Text variant={'body1Medium'}>Extension de couverture</Text>
      <ProductOptionListItem
        product={productInformation.product}
        option={historyTakeoverOption}
        stateProxy={stateProxy}
        configuration={historyTakeoverOptionConfiguration}
        subscriptionId={subscriptionId}
        dataTestId="history-takeover-option"
      />
      {historyTakeoverOption.isActive && stateProxy.read(uiElement.dimensions.rcdaOptionHistoryTakeover) ? (
        <RcdaHistoryTakeoverYears uiElement={uiElement} stateProxy={stateProxy} subscriptionId={subscriptionId} />
      ) : (
        <></>
      )}
    </div>
  )
})

type RcdaHistoryTakeoverYearsProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
  subscriptionId: string
}
export const RcdaHistoryTakeoverYears = memo<RcdaHistoryTakeoverYearsProps>(function RcdaHistoryTakeoverYears({
  uiElement,
  stateProxy,
  subscriptionId,
}) {
  const language = useLanguage()
  const handleChange = useCallback(
    (valueString?: string | null) => {
      if (!valueString) return
      stateProxy.write(uiElement.dimensions.rcdaOptionHistoryTakeoverYears, valueString)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        rcda_history_takeover_years: valueString,
        yearly_total_premium: amountToNumber(stateProxy.readRequired(uiElement.dimensions.quote).yearlyTotalPremium),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcda_deductible: null,
        option: null,
        rcph_loi: null,
        es_rcph_loi: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  const value = stateProxy.read(uiElement.dimensions.rcdaOptionHistoryTakeoverYears)
  const valueString = value == undefined ? null : value.toString()

  const startDate = stateProxy.read(uiElement.dimensions.rcdaOptionHistoryTakeoverStartDate)
  const inCreation = stateProxy.read(uiElement.dimensions.rcdaCompanyInCreation)

  return (
    <DropdownWithDescription
      title="Période de la reprise du passé"
      description={
        startDate
          ? `Date de début effective : ${calendarDateToString(startDate, PARIS, 'DATE_FULL', language)}`
          : 'Relatif à la date de début du contrat'
      }
      value={valueString}
      values={inCreation ? rcdaOptionHistoryTakeoverYearsValues : NOT_IN_CREATION_YEARS_VALUES}
      labels={rcdaOptionHistoryTakeoverYearsLabels}
      onChange={handleChange}
      aria-label="Période de la reprise du passé"
      size="large"
    />
  )
})

const NOT_IN_CREATION_YEARS_VALUES = rcdaOptionHistoryTakeoverYearsValues.filter((value) => value !== '6months')
