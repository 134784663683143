import { mutaActualCosts, mutaEmpty, mutaOneHundredPct, mutaYes, type MutaGuarantees } from '../muta-guarantees.js'

export const mutaEss2Guarantees: MutaGuarantees = {
  HO_FDS_CO: mutaActualCosts,
  HO_FDS_NCO: mutaOneHundredPct,
  HO_HO: {
    type: 'optamThreeYears',
    PA: {
      FY: { type: 'percentage', percentage: 125 },
      SY: { type: 'percentage', percentage: 130 },
      TY: { type: 'percentage', percentage: 140 },
    },
    PNA: mutaOneHundredPct,
  },
  HO_HO_HO: mutaOneHundredPct,
  HO_CH_FY: { type: 'amount', amount: 20 },
  HO_CH_SY: { type: 'amount', amount: 25 },
  HO_CH_TY: { type: 'amount', amount: 30 },
  HO_SA: mutaEmpty,
  HO_FJ: mutaActualCosts,
  HO_CO_HO: mutaEmpty,
  HO_HAD: mutaOneHundredPct,
  MD_MN: mutaEmpty,
  MD_PREV: mutaOneHundredPct,
  MD_PROT: mutaOneHundredPct,
  SC_HM_MG: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 125 },
    PNA: mutaOneHundredPct,
  },
  SC_HM_MS: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 125 },
    PNA: mutaOneHundredPct,
  },
  SC_HM_IM: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HM_ATM: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HP: mutaOneHundredPct,
  SC_LAB: mutaOneHundredPct,
  SC_TRANS: mutaOneHundredPct,
  SC_MEDIC_PEC: mutaOneHundredPct,
  SC_PAPP: mutaEmpty,
  SC_MEDIC_NPEC: mutaEmpty,
  DEN_HPS: mutaOneHundredPct,
  DEN_NPC_RB: mutaEmpty,
  DEN_ORT_RB_FY: mutaOneHundredPct,
  DEN_ORT_RB_SY: mutaOneHundredPct,
  DEN_ORT_RB_TY: { type: 'percentage', percentage: 125 },
  DEN_PROT_RB_FY: { type: 'percentage', percentage: 125 },
  DEN_PROT_RB_SY: { type: 'percentage', percentage: 125 },
  DEN_PROT_RB_TY: { type: 'percentage', percentage: 150 },
  DEN_PLAF_REMB_FY: { type: 'amount', amount: 500 },
  DEN_PLAF_REMB_SY: { type: 'amount', amount: 800 },
  DEN_PLAF_REMB_TY: { type: 'amount', amount: 1000 },
  OPT_SIMP_MONT_FY: { type: 'amount', amount: 100 },
  OPT_SIMP_MONT_SY: { type: 'amount', amount: 125 },
  OPT_SIMP_MONT_TY: { type: 'amount', amount: 150 },
  OPT_COMP_MONT_FY: { type: 'amount', amount: 200 },
  OPT_COMP_MONT_SY: { type: 'amount', amount: 225 },
  OPT_COMP_MONT_TY: { type: 'amount', amount: 250 },
  OPT_T_COMP_MONT_FY: { type: 'amount', amount: 200 },
  OPT_T_COMP_MONT_SY: { type: 'amount', amount: 225 },
  OPT_T_COMP_MONT_TY: { type: 'amount', amount: 250 },
  OPT_SIMP_COMP_FY: { type: 'amount', amount: 125 },
  OPT_SIMP_COMP_SY: { type: 'amount', amount: 150 },
  OPT_SIMP_COMP_TY: { type: 'amount', amount: 175 },
  OPT_SIMP_T_COMP_FY: { type: 'amount', amount: 125 },
  OPT_SIMP_T_COMP_SY: { type: 'amount', amount: 150 },
  OPT_SIMP_T_COMP_TY: { type: 'amount', amount: 175 },
  OPT_COMP_T_COMP_FY: { type: 'amount', amount: 200 },
  OPT_COMP_T_COMP_SY: { type: 'amount', amount: 225 },
  OPT_COMP_T_COMP_TY: { type: 'amount', amount: 250 },
  OPT_FORF_LENT: { type: 'percentage-and-amount', percentage: 100, amount: 25 },
  AUD_APP_PROT: mutaOneHundredPct,
  AUD_FORF_AUD: mutaEmpty,
  AUD_ACCESS: mutaOneHundredPct,
  CUR_HO_CONV: mutaEmpty,
  CUR_HO_HCONV: mutaEmpty,
  CUR_FRAIS_ETAB: mutaEmpty,
  CUR_TRANS_HEB: mutaEmpty,
  SER_TIERS_PAY: mutaYes,
  SER_ASSIST: mutaYes,
  SER_AUTO_REMB: mutaYes,
  ASS_TELE_CONSULT_MEDI: { type: 'sessionPerInsured', session: 10 },
  ASS_COACH_WELLNESS: { type: 'session', session: 4 },
  ASS_ACCOMP_PSY: { type: 'session', session: 4 },
  ASS_HOME_HELP_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_HOME_HELP_TO: {
    type: 'amount',
    amount: 500,
  },
  ASS_TRANS_TAXI_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_TRANS_TAXI_TO: {
    type: 'amount',
    amount: 500,
  },
}
