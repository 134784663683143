import { css } from '@emotion/react'
import type { CalloutSubscriptionUiElement } from '@orus.eu/dimensions'
import { Avatar, Callout, colors, emoji, spacing } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const CalloutSubscriptionUiElementBlock = ifStateProxy<CalloutSubscriptionUiElement>(
  function RevenueLimitSubscriptionUiElementBlock({ uiElement }) {
    const text = uiElement.text

    return (
      <Callout
        variant="content"
        avatar={<Avatar src={emoji.lightBulb} />}
        css={css`
          margin-top: ${spacing[70]};
          background-color: ${colors.periwinkle.dark};
          color: ${colors.blue[800]};
        `}
        title="Bon à savoir"
      >
        {text}
      </Callout>
    )
  },
)
