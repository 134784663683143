import { css } from '@emotion/react'
import { AbstractDimension } from '@orus.eu/dimensions'
import { FlexSpacedColumn, spacing, TextInputLabelWrapper, typedMemo, useLanguage } from '@orus.eu/pharaoh'
import { Textarea } from '@orus.eu/pharaoh/src/components/atoms/textarea'
import { useCallback, useMemo, useState, type ChangeEvent } from 'react'
import type { StateProxy } from '../../subscription-v2-props'
import { getCustomClausesDimensionsBySelectedProduct } from './custom-clauses-utils'

type CustomClausesDimensionFieldsProps = {
  stateProxy: StateProxy
}

export const CustomClausesDimensionFields = typedMemo(function CustomClausesDimensionFields({
  stateProxy,
}: CustomClausesDimensionFieldsProps) {
  const language = useLanguage()
  const [clausesValues, setClausesValues] = useState(() => {
    const values: Record<string, string | undefined> = {}
    getCustomClausesDimensionsBySelectedProduct(stateProxy).forEach((dimension) => {
      values[dimension.name] = stateProxy.read(dimension)
    })
    return values
  })

  const clausesDimension = useMemo(() => {
    return getCustomClausesDimensionsBySelectedProduct(stateProxy)
  }, [stateProxy])

  const updateClause = useCallback(
    (dimension: AbstractDimension, event: ChangeEvent<HTMLTextAreaElement>) => {
      stateProxy.write(dimension, event.target.value)
      setClausesValues((prev) => ({ ...prev, [dimension.name]: event.target.value }))
    },
    [stateProxy],
  )

  return (
    <FlexSpacedColumn
      css={css`
        min-width: 100%;
      `}
      margin={`${spacing[60]} ${spacing[40]}`}
    >
      {clausesDimension.map((dimension) => {
        return (
          <TextInputLabelWrapper
            key={dimension.name}
            label={dimension.displayNames[language]}
            infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
          >
            <Textarea
              size="large"
              minRows={1}
              maxRows={5}
              value={clausesValues[dimension.name]!}
              placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
              onChange={(event) => updateClause(dimension, event)}
            />
          </TextInputLabelWrapper>
        )
      })}
    </FlexSpacedColumn>
  )
})
