import styled from '@emotion/styled'
import type { EmptyStateQuoteUiElement } from '@orus.eu/dimensions'
import { Avatar, colorTokens, spacing, Text } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const EmptyStateQuoteUiElementBlock = ifStateProxy<EmptyStateQuoteUiElement>(
  function EmptyStateQuoteUiElementBlock() {
    return (
      <EmptyStateContainer>
        <Avatar icon="folder-light" size="40" color={colorTokens['color-fg-base']} />
        <Text variant="caption" color={colorTokens['color-text-base-basic']}>
          Sélectionnez une activité et un produit <br />
          d’assurance pour obtenir une offre.
        </Text>
      </EmptyStateContainer>
    )
  },
)

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: ${spacing[40]};
`
