import {
  type MutaGuaranteeLimit,
  type MutaGuaranteeLimitOptam,
  type MutaGuaranteeLimitOptamThreeYears,
} from './muta-guarantees-limit.js'

/**
 * Guarantees for Mutuelle Acheel
 * Please also keep updated the comments given the complexity of those guarantees
 */
export type MutaGuarantees = {
  //#region Hospitalisation (HO)
  /**
   * Frais de sejour conventionné
   */
  HO_FDS_CO: MutaGuaranteeLimit
  /**
   * Frais de sejour non conventionné
   */
  HO_FDS_NCO: MutaGuaranteeLimit
  /**
   * Honoraires - Praticiens adhérents ou non à un dispositif de pratique tarifaire maîtrisée - 1ere année à 3eme année
   */
  HO_HO: MutaGuaranteeLimitOptamThreeYears
  /**
   * Honoraires : Chirurgie, anesthésie, obstétrie (hors chirurgie dentaire) en établissement non conventionnés
   */
  HO_HO_HO: MutaGuaranteeLimit
  /**
   * Chambre particulière en secteur conventionné ou non conventionné - 1ere année
   */
  HO_CH_FY: MutaGuaranteeLimit
  /**
   * Chambre particulière en secteur conventionné ou non conventionné - 2eme année
   */
  HO_CH_SY: MutaGuaranteeLimit
  /**
   * Chambre particulière en secteur conventionné ou non conventionné - 3eme année
   */
  HO_CH_TY: MutaGuaranteeLimit
  /**
   * Forfait séjour accompagnant (Lit et repas) (par jour) - A partir de la 2ème année
   */
  HO_SA: MutaGuaranteeLimit
  /**
   * Forfait journalier hospitalier
   */
  HO_FJ: MutaGuaranteeLimit
  /**
   * Confort hospitalier (téléphone, internet, télévision, maxi 15 jours par an) (à partir de la deuxième année) (/jour)
   */
  HO_CO_HO: MutaGuaranteeLimit
  /**
   * Hospitalisation à domicile
   */
  HO_HAD: MutaGuaranteeLimit
  //#endregion
  //#region Medecine douce (MD)
  /**
   * Médecine naturelle - Ostéopathe, pédicure, podologue, étiopathe, homéopathe, acupuncteur, chiropracteur, mésothérapeute, réflexologue, diététicien, nutritionniste (Maximum 30€ par séance)(/an)
   */
  MD_MN: MutaGuaranteeLimit
  /**
   * Prise en charge de l’ensemble des actes de prévention prévus dans le cadre du contrat responsable (arrêté ministériel du 8 juin 2006)
   */
  MD_PREV: MutaGuaranteeLimit
  /**
   * Prothèses et appareillages (autres que dentaires et audio)
   */
  MD_PROT: MutaGuaranteeLimit
  //#endregion
  //#region Soins courants (SC)
  /**
   * Consultation de médecins généralistes
   */
  SC_HM_MG: MutaGuaranteeLimitOptam
  /**
   * Consultation de médecins spécialistes
   */
  SC_HM_MS: MutaGuaranteeLimitOptam
  /**
   * Imagerie médicale
   */
  SC_HM_IM: MutaGuaranteeLimitOptam
  /**
   * Actes de techniques médicaux (ATM)
   */
  SC_HM_ATM: MutaGuaranteeLimitOptam
  /**
   * Honoraires paramédicaux
   */
  SC_HP: MutaGuaranteeLimit
  /**
   * Analyses et examens de laboratoires
   */
  SC_LAB: MutaGuaranteeLimit
  /**
   * Transport (sauf cures thermales)
   */
  SC_TRANS: MutaGuaranteeLimit
  /**
   * Médicaments pris en charge par le régime de base
   */
  SC_MEDIC_PEC: MutaGuaranteeLimit
  /**
   * Petit appareillage
   */
  SC_PAPP: MutaGuaranteeLimit
  /**
   * Médicaments non pris en charge par le régime de base (/an)
   */
  SC_MEDIC_NPEC: MutaGuaranteeLimit
  //#endregion
  //#region Dentaire (DEN)
  /**
   * Soins (dont inlays, onlays, scellement des sillons) (hors panier de soins 100% santé)
   */
  DEN_HPS: MutaGuaranteeLimit
  /**
   * Dentaire non pris en charge par le RB (implants non pris en charge, orthodontie pour adulte) forfait par an
   */
  DEN_NPC_RB: MutaGuaranteeLimit
  /**
   * Orthodontie prises en charge par le régime de base - 1ère année
   */
  DEN_ORT_RB_FY: MutaGuaranteeLimit
  /**
   * Orthodontie prises en charge par le régime de base - 2eme année
   */
  DEN_ORT_RB_SY: MutaGuaranteeLimit
  /**
   * Orthodontie prises en charge par le régime de base - 3eme année et suivantes
   */
  DEN_ORT_RB_TY: MutaGuaranteeLimit
  /**
   * Prothèses dentaires, inlays-core et implantologie pris en charge par le RB (hors panier de soins 100% santé) - 1ère année
   */
  DEN_PROT_RB_FY: MutaGuaranteeLimit
  /**
   * Prothèses dentaires, inlays-core et implantologie pris en charge par le RB (hors panier de soins 100% santé) - 2eme année
   */
  DEN_PROT_RB_SY: MutaGuaranteeLimit
  /**
   * Prothèses dentaires, inlays-core et implantologie pris en charge par le RB (hors panier de soins 100% santé) - 3eme année et suivantes
   */
  DEN_PROT_RB_TY: MutaGuaranteeLimit
  /**
   * Plafonds de remboursement annuel (hors soins) incluant les montants remboursés dans le panier 100% Santé - 1ère année
   */
  DEN_PLAF_REMB_FY: MutaGuaranteeLimit
  /**
   * Plafonds de remboursement annuel (hors soins) incluant les montants remboursés dans le panier 100% Santé - 2eme année
   */
  DEN_PLAF_REMB_SY: MutaGuaranteeLimit
  /**
   * Plafonds de remboursement annuel (hors soins) incluant les montants remboursés dans le panier 100% Santé - 3eme année et suivantes
   */
  DEN_PLAF_REMB_TY: MutaGuaranteeLimit
  //#endregion
  //#region Optique (OPT)
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres simples et monture - 1ère année
   */
  OPT_SIMP_MONT_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres simples et monture - 2eme année
   */
  OPT_SIMP_MONT_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres simples et monture - 3eme année et suivantes
   */
  OPT_SIMP_MONT_TY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres complexes et monture - 1ère année
   */
  OPT_COMP_MONT_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres complexes et monture - 2eme année
   */
  OPT_COMP_MONT_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres complexes et monture - 3eme année et suivantes
   */
  OPT_COMP_MONT_TY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres très complexes et monture - 1ère année
   */
  OPT_T_COMP_MONT_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres très complexes et monture - 2eme année
   */
  OPT_T_COMP_MONT_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - Verres très complexes et monture - 3eme année et suivantes
   */
  OPT_T_COMP_MONT_TY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre complexe - 1ère année
   */
  OPT_SIMP_COMP_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre complexe - 2eme année
   */
  OPT_SIMP_COMP_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre complexe - 3eme année et suivantes
   */
  OPT_SIMP_COMP_TY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre très complexe - 1ère année
   */
  OPT_SIMP_T_COMP_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre très complexe - 2eme année
   */
  OPT_SIMP_T_COMP_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre simple + 1 verre très complexe - 3eme année et suivantes
   */
  OPT_SIMP_T_COMP_TY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre complexe + 1 verre très complexe - 1ère année
   */
  OPT_COMP_T_COMP_FY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre complexe + 1 verre très complexe - 2eme année
   */
  OPT_COMP_T_COMP_SY: MutaGuaranteeLimit
  /**
   * Equipement composé de 2 verres et d'une monture - monture dans la limite de 100 € - 1 verre complexe + 1 verre très complexe - 3eme année et suivantes
   */
  OPT_COMP_T_COMP_TY: MutaGuaranteeLimit
  /**
   * Forfait annuel pour lentilles prescrites prises en charge ou non par le RB
   */
  OPT_FORF_LENT: MutaGuaranteeLimit
  //#endregion
  //#region Aides auditives (AUD)
  /**
   * Appareillages et prothèses auditives (Aide auditive de Classe II***) prises en charge par le régime de base
   */
  AUD_APP_PROT: MutaGuaranteeLimit
  /**
   * Forfait annuel appareil auditif (hors 100% santé)
   */
  AUD_FORF_AUD: MutaGuaranteeLimit
  /**
   * Accessoires (piles, écouteurs, coque …)
   */
  AUD_ACCESS: MutaGuaranteeLimit
  //#endregion
  //#region Cure thermale prise en charge par le RB (CUR)
  /**
   * Honoraires dans le cadre de la convention
   */
  CUR_HO_CONV: MutaGuaranteeLimit
  /**
   * Honoraires hors convention
   */
  CUR_HO_HCONV: MutaGuaranteeLimit
  /**
   * Frais d’établissement
   */
  CUR_FRAIS_ETAB: MutaGuaranteeLimit
  /**
   * Transport et hébergement (par an)
   */
  CUR_TRANS_HEB: MutaGuaranteeLimit
  //#endregion
  //#region Services offerts (SER)
  /**
   * Tiers payant
   */
  SER_TIERS_PAY: MutaGuaranteeLimit
  /**
   * Assistance
   */
  SER_ASSIST: MutaGuaranteeLimit
  /**
   * Automatisation des remboursements
   */
  SER_AUTO_REMB: MutaGuaranteeLimit
  //#endregion
  //#region Assistance (ASS)
  /**
   * Téléconsultation et téléprescription médicamenteuse
   */
  ASS_TELE_CONSULT_MEDI: MutaGuaranteeLimit
  /**
   * Coaching bien-être
   */
  ASS_COACH_WELLNESS: MutaGuaranteeLimit
  /**
   * Accompagnement psychologique
   */
  ASS_ACCOMP_PSY: MutaGuaranteeLimit
  /**
   * Aide à domicile
   */
  ASS_HOME_HELP_FROM: MutaGuaranteeLimit
  /**
   * Aide à domicile
   */
  ASS_HOME_HELP_TO: MutaGuaranteeLimit
  /**
   * Transport taxi
   */
  ASS_TRANS_TAXI_FROM: MutaGuaranteeLimit
  /**
   * Transport taxi
   */
  ASS_TRANS_TAXI_TO: MutaGuaranteeLimit

  //#endregion
}

export type MutaGuarantee = keyof MutaGuarantees

export const mutaOneHundredPct: MutaGuaranteeLimit = { type: 'percentage', percentage: 100 }
export const mutaActualCosts: MutaGuaranteeLimit = { type: 'realCost' }
export const mutaEmpty: MutaGuaranteeLimit = { type: 'notReimbursed' }
export const mutaYes: MutaGuaranteeLimit = { type: 'yes' }
