import type { AddressMapSubscriptionUiElement } from '@orus.eu/dimensions'
import { EmbeddedGoogleMap } from '../../../atoms/embeded-google-map'
import { ifStateProxy } from '../if-state-proxy'

export const AddressMapSubscriptionUiElementBlock = ifStateProxy<AddressMapSubscriptionUiElement>(
  function AddressMapSubscriptionUiElementBlock({ uiElement, stateProxy }) {
    const dimension = uiElement.dimension
    const address = stateProxy.read(dimension)
    return <EmbeddedGoogleMap address={address} />
  },
)
