import type { MutaBeneficiary } from '@orus.eu/dimensions'

export const isMemberCompleted = (beneficiary: MutaBeneficiary): boolean => {
  const isChildRankOk = beneficiary.type !== 'CHILD' || beneficiary.socialSecurityRank !== undefined

  if (
    beneficiary.lastName &&
    beneficiary.firstName &&
    beneficiary.birthCity &&
    beneficiary.birthCountry &&
    beneficiary.socialSecurityNumber &&
    beneficiary.socialSecurityOrganismNumber &&
    isChildRankOk
  )
    return true

  return false
}
