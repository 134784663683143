import { css } from '@emotion/react'
import type { ActivityInput } from '@orus.eu/activity'
import { type ActivityBackofficeSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { Button, Dialog, TextInputLabelWrapper, spacing, useAsyncCallback, useDialogVisibility } from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { ActivityCoverageDialog } from '@orus.eu/pharaoh/src/components/features/rcda'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useCallback, useMemo, useState } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { backofficeGroupMappingLoader } from '../../../../lib/group-mapping-util'
import { ActivityGroupSearchField } from '../../../molecules/activity-group-search-field'
import { ActivityDetailButton } from '../../backoffice/common/backoffice-quote-editor-v2/quote-editor-v2-risk-carrier-info'
import { requiredFieldMixin } from '../../backoffice/common/backoffice-quote-editor-v2/quote-editor-v2-util'
import { ifStateProxy } from '../if-state-proxy'

export const ActivityBackofficeSearchSubscriptionUiElementBlock =
  ifStateProxy<ActivityBackofficeSearchSubscriptionUiElement>(
    function ActivityBackofficeSearchSubscriptionUiElementBlock(props) {
      const { uiElement, stateProxy } = props

      const activity = stateProxy.read<ActivityInput>(uiElement.dimensions.activity)
      const operatingZone = stateProxy.readRequired<OperatingZone>(uiElement.dimensions.operatingZone)
      const availableRiskCarrierProducts = stateProxy.read(uiElement.dimensions.availableRiskCarrierProducts) || []

      const isRcdaActivity = availableRiskCarrierProducts.includes('rcda')

      const [dialogOpen, setDialogOpen] = useState(false)

      const closeDialog = useCallback(() => setDialogOpen(false), [])

      const openDialog = useCallback(() => setDialogOpen(true), [])

      const state = useMemo(() => {
        return {
          activity: stateProxy.read(uiElement.dimensions.activity),
          mrphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.mrphHiscoxActivitiesNames),
          rcphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.rcphHiscoxActivitiesNames),
          rcphProductLabel: stateProxy.read(uiElement.dimensions.rcphProductLabel),
          mrpwWakamMainActivity: stateProxy.read(uiElement.dimensions.mrpwWakamMainActivity),
          rcdaAxeriaActivityNames: stateProxy.read(uiElement.dimensions.rcdaAxeriaActivityNames),
        }
      }, [stateProxy, uiElement.dimensions])

      const handleActivitySelected = useCallback(
        (newValue: ActivityInput | null) => {
          stateProxy.write(uiElement.dimensions.activity, newValue)
        },
        [stateProxy, uiElement.dimensions],
      )

      const {
        show: showActivityDetailsDialog,
        hide: hideActivityDetailsDialog,
        visible: visibleActivityDetailsDialog,
      } = useDialogVisibility(`activity-details-${activity?.activity}`)

      const [rcdaActivitiesScope] = trpcReact.rcda.getRcdaExclusions.useSuspenseQuery()
      const { scope } = rcdaActivitiesScope

      return (
        <>
          {activity ? (
            <RowContainer size="medium" variant="spacing">
              <Row
                title={activity?.displayName}
                titleVariant={'body2'}
                buttonGroup={
                  <ButtonGroup>
                    {isRcdaActivity ? (
                      <Button variant="secondary" onClick={showActivityDetailsDialog}>
                        Plus de détails
                      </Button>
                    ) : (
                      <ActivityDetailButton state={state} />
                    )}

                    <Button
                      icon="pen-regular"
                      size="small"
                      variant="secondary"
                      avatarPosition="left"
                      onClick={openDialog}
                      ariaLabel="modifier-activité"
                    >
                      Modifier
                    </Button>
                  </ButtonGroup>
                }
              />
            </RowContainer>
          ) : (
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button
                icon="plus-solid"
                size="small"
                variant="secondary"
                avatarPosition="left"
                onClick={openDialog}
                ariaLabel="modifier-activité"
                css={css`
                  ${requiredFieldMixin(uiElement.highlight, activity)}
                `}
              >
                Ajouter
              </Button>
            </div>
          )}

          {dialogOpen ? (
            <PickActivityDialog
              onClose={closeDialog}
              onActivitySelected={handleActivitySelected}
              operatingZone={operatingZone}
            />
          ) : (
            <></>
          )}

          {visibleActivityDetailsDialog && activity && isRcdaActivity ? (
            <ActivityCoverageDialog
              activity={activity.displayName}
              mainActivities={scope[activity.activity].mainActivities}
              secondaryActivities={scope[activity.activity].secondaryActivities}
              forbiddenActivities={scope[activity.activity].forbiddenActivities}
              onClose={hideActivityDetailsDialog}
            />
          ) : null}
        </>
      )
    },
  )

export type PickPolicyActivityDialogProps = {
  operatingZone: OperatingZone
  onClose: () => void
  onActivitySelected: (value: ActivityInput) => void
}

/**
 * This dialog allows picking an activity by first looking for a group, then choosing an activity inside the group.
 */
const PickActivityDialog = memo<PickPolicyActivityDialogProps>(function PickPolicyActivityDialog({
  operatingZone,
  onClose,
  onActivitySelected,
}) {
  const [loading, setLoading] = useState(false)

  const handleActivitySelected = useCallback(
    (activity: ActivityInput) => {
      onActivitySelected(activity)
      onClose()
    },
    [onActivitySelected, onClose],
  )

  const handleGroupSelected = useAsyncCallback(
    async (group?: string) => {
      if (!group) {
        return
      }
      setLoading(true)

      const { mainActivityId } = checkDefinedAndNotNull(
        await trpc.activities.getGroupMappingForBackoffice.query({ group, operatingZone }),
      )

      // select the first activity in the group, as all activities are equivalents
      handleActivitySelected({
        activity: mainActivityId,
        displayName: group,
      })

      setLoading(false)
    },
    [handleActivitySelected, operatingZone],
  )

  return (
    <Dialog
      size="large"
      title="Choisis une activité"
      onClose={onClose}
      secondaryActionLabel="Annuler"
      onSecondaryAction={onClose}
    >
      <div
        css={css`
          width: 800px;
        `}
      >
        <TextInputLabelWrapper
          label="Recherche"
          css={css`
            margin-top: ${spacing[70]};
          `}
        >
          <ActivityGroupSearchField
            operatingZone={operatingZone}
            disabled={loading}
            groupLoader={backofficeGroupMappingLoader}
            onChange={handleGroupSelected}
          />
        </TextInputLabelWrapper>
      </div>
    </Dialog>
  )
})
