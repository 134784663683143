import { TechnicalError } from '@orus.eu/error'
import { uploadStatusSchema, type UploadSuccessful } from '@orus.eu/upload'

export async function uploadFileFromBuffer(path: string, file: File): Promise<UploadSuccessful> {
  const formData = new FormData()
  formData.append('file', file)

  return fetch(path, {
    method: 'POST',
    body: formData,
    cache: 'no-store',
  }).then(async (response) => {
    if (response.status !== 200) {
      throw new TechnicalError(`Failed to upload file: ${response.status}`)
    }

    const parseResult = uploadStatusSchema.safeParse(await response.json())

    if (parseResult.error) {
      throw new TechnicalError(`Failed to parse upload status: ${parseResult.error.message}`)
    }

    if (parseResult.data.ok === false) {
      throw new TechnicalError(`Failed to parse upload status: ${parseResult.data.reason}`)
    }

    return parseResult.data
  })
}
