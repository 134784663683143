import { type MutaFormulaType } from '../muta-formula-dimension.js'
import {
  mutaEss1Guarantees,
  mutaEss2Guarantees,
  mutaEss3Guarantees,
  mutaEss4Guarantees,
  mutaPriv1Guarantees,
  mutaPriv2Guarantees,
  mutaPriv3Guarantees,
  mutaPriv4Guarantees,
  mutaPriv5Guarantees,
  mutaPriv6Guarantees,
  mutaPriv7Guarantees,
  mutaPriv8Guarantees,
} from './guarantees-by-formula/index.js'
import { type MutaGuarantees } from './muta-guarantees.js'

export const mutaFormulaGuarantees: Record<MutaFormulaType, MutaGuarantees> = {
  'ESSE-1': mutaEss1Guarantees,
  'ESSE-2': mutaEss2Guarantees,
  'ESSE-3': mutaEss3Guarantees,
  'ESSE-4': mutaEss4Guarantees,
  'PRIV-1': mutaPriv1Guarantees,
  'PRIV-2': mutaPriv2Guarantees,
  'PRIV-3': mutaPriv3Guarantees,
  'PRIV-4': mutaPriv4Guarantees,
  'PRIV-5': mutaPriv5Guarantees,
  'PRIV-6': mutaPriv6Guarantees,
  'PRIV-7': mutaPriv7Guarantees,
  'PRIV-8': mutaPriv8Guarantees,
}
