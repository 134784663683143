import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import { downloadFileFromBuffer, useCrash, useEnqueueToast, useLanguage, useTranslate } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useCallback, useState } from 'react'
import { trpc } from '../../client'
import { dynamicFileNames } from '../download-filenames'

export type UseDownloadAgreedTermsProps = {
  subscriptionId: string
  changes: SubscriptionDimensionnedState
  email: string | undefined
}

/**
 * Download the agreed terms draft with last changes
 * */
export function useDownloadAgreedTerms({ changes, email, subscriptionId }: UseDownloadAgreedTermsProps): {
  download: () => Promise<void>
  currentEdgeCase: 'input-incomplete' | undefined
  downloadInProgress: boolean
} {
  const translate = useTranslate()
  const language = useLanguage()
  const crash = useCrash()
  const { enqueueToast } = useEnqueueToast()
  const [currentEdgeCase, setCurrentEdgeCase] = useState<'input-incomplete' | undefined>(undefined)
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const downloadAgreedTerms = useCallback(async () => {
    setDownloadInProgress(true)
    setCurrentEdgeCase(undefined)
    const result = await trpc.pendingSubscriptions.generateAgreedTermsDraftV2.mutate({
      subscriptionId,
      changes,
      isFunnel: true,
    })

    if (isFailure(result)) {
      setDownloadInProgress(false)
      switch (result.problem.type) {
        case 'temporary-api-failure':
          crash({ type: 'temporary-problem' })
          return
        case 'draft-agreed-terms-input-incomplete':
          setCurrentEdgeCase('input-incomplete')
          return
      }
    }

    const fileName = dynamicFileNames['agreed-terms-draft'](email, language)

    try {
      await downloadFileFromBuffer(result.output, fileName)
    } catch (err) {
      console.error('Failed to download file', err)
      enqueueToast('Échec du téléchargement du fichier.', { variant: 'danger' })
    } finally {
      setDownloadInProgress(false)
    }

    enqueueToast(translate('download_successful'), { variant: 'success' })
  }, [subscriptionId, changes, email, language, enqueueToast, translate, crash])

  return { download: downloadAgreedTerms, currentEdgeCase, downloadInProgress }
}
