import { css } from '@emotion/react'

import styled from '@emotion/styled'
import { addAmounts, amountToString, multiplyByRate, newFinancialRate, zeroAmount, type Amount } from '@orus.eu/amount'
import {
  getEffectiveYearlyPartnerCommissionAmount,
  getPartnerCommissionAmountByRecurrence,
  type AggregatedQuote,
  type PartnerFeesCardUiElement,
  type PartnerQuotesDimensions,
  type PaymentRecurrence,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Badge, borderRadius, colorTokens, shadow, spacing, Text } from '@orus.eu/pharaoh'
import type { RcphQuoteV2 } from '@orus.eu/quote'
import { useMemo } from 'react'
import { trpcReact } from '../../../../client'
import { ifStateProxy } from '../if-state-proxy'

export const PartnerFeesCardUiElementBlock = ifStateProxy<PartnerFeesCardUiElement>(
  function PartnerFeesCardUiElementBlock({ uiElement, stateProxy }) {
    const brokerDiscount = stateProxy.read(uiElement.dimensions.brokerDiscount)
    const hasBrokerDiscount = brokerDiscount !== undefined && brokerDiscount !== 'none'

    const quote = stateProxy.readRequired(uiElement.dimensions.quote)

    const rcphQuoteV2 = stateProxy.read(uiElement.dimensions.rcphQuoteV2)
    const mrpwQuote = stateProxy.read(uiElement.dimensions.mrpwQuote)
    const mutaQuote = stateProxy.read(uiElement.dimensions.mutaQuote)
    const rcdaQuote = stateProxy.read(uiElement.dimensions.rcdaQuote)
    const mrphQuoteV2 = stateProxy.read(uiElement.dimensions.mrphQuoteV2)

    const organizationRates = trpcReact.organizations.getOrganizationRates.useQuery(
      stateProxy.readRequired(uiElement.dimensions.organization),
      { enabled: !!rcphQuoteV2 },
    )

    const partnerApplicationFee = quote.partnerApplicationPremium ?? zeroAmount
    const paymentRecurrence = quote.paymentRecurrence
    const partnerManagementFee =
      (paymentRecurrence === 'yearly' ? quote.yearlyPartnerManagementPremium : quote.monthlyPartnerManagementPremium) ??
      zeroAmount

    const commissionAmount = useMemo(() => {
      return getEffectiveYearlyPartnerCommissionAmount(
        { rcphQuoteV2, mutaQuote, rcdaQuote, mrphQuoteV2, mrpwQuote },
        quote.paymentRecurrence,
      )
    }, [mrphQuoteV2, mrpwQuote, mutaQuote, quote.paymentRecurrence, rcdaQuote, rcphQuoteV2])

    //Rework this part when every product have the discount only first year rules applied
    const allPartnerFees = [
      {
        name: 'Commission avec discount (1e année)',
        amount: commissionAmount,
        display: hasBrokerDiscount && rcphQuoteV2,
        badge: 'par an',
      },
      {
        name: 'Commission annuelle',
        amount:
          rcphQuoteV2 && organizationRates.data && hasBrokerDiscount
            ? RcphWithoutDiscountCommissionAmount(
                rcphQuoteV2,
                checkDefinedAndNotNull(organizationRates.data.rcph),
                paymentRecurrence,
              )
            : commissionAmount,
        display: true,
        badge: 'par an',
      },
      {
        name: 'Frais de gestion',
        amount: partnerManagementFee,
        display: true,
        badge: paymentRecurrence === 'monthly' ? 'par mois' : 'par an',
      },
      {
        name: 'Frais de dossier',
        amount: partnerApplicationFee,
        display: true,
        badge: '1er paiement',
      },
    ]

    return (
      <>
        <PartnerFeesCard>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Text variant="subtitle2">Votre rémunération</Text>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                gap: ${spacing[30]};
                align-items: center;
              `}
            >
              <Text variant="body2Medium">
                {amountToString(
                  getRecurringPartnerFees(quote, { rcphQuoteV2, mutaQuote, rcdaQuote, mrphQuoteV2, mrpwQuote }),
                  amountToStringOptions,
                )}
              </Text>
              <Badge variant="neutral">{paymentRecurrence === 'monthly' ? 'par mois' : 'par an'}</Badge>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[20]};
            `}
          >
            {allPartnerFees.map(
              (fee) =>
                fee.display && (
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                    key={fee.name}
                  >
                    <Text variant="body2">{fee.name}</Text>
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        gap: ${spacing[30]};
                        align-items: center;
                      `}
                    >
                      <Text variant="body2Medium">{amountToString(fee.amount, amountToStringOptions)}</Text>
                      <Badge variant="neutral">{fee.badge}</Badge>
                    </div>
                  </div>
                ),
            )}
          </div>
        </PartnerFeesCard>
      </>
    )
  },
)

const PartnerFeesCard = styled('div')`
  border-radius: ${borderRadius[30]};
  border: 1px solid ${colorTokens['color-stroke-base']};
  box-shadow: ${shadow.bottom[20]};
  padding: ${spacing[60]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`

function RcphWithoutDiscountCommissionAmount(
  rcphQuoteV2: RcphQuoteV2,
  organizationRcphRate: number,
  paymentRecurrence: PaymentRecurrence,
): Amount {
  return multiplyByRate(
    paymentRecurrence === 'monthly'
      ? rcphQuoteV2?.monthly.withoutAnyDiscount.total.annualizedExTax
      : rcphQuoteV2?.yearly.withoutAnyDiscount.total.exTax,
    newFinancialRate(organizationRcphRate),
  )
}

function getRecurringPartnerFees(quote: AggregatedQuote, state: PartnerQuotesDimensions): Amount {
  if (quote.paymentRecurrence === 'monthly') {
    const monthlyPartnerManagementPremium = quote.monthlyPartnerManagementPremium ?? zeroAmount
    const monthlyBrokerCommission = getPartnerCommissionAmountByRecurrence(state, 'monthly')
    return addAmounts(monthlyPartnerManagementPremium, monthlyBrokerCommission)
  }
  const yearlyPartnerManagementPremium = quote.yearlyPartnerManagementPremium ?? zeroAmount
  const yearlyBrokerCommission = getPartnerCommissionAmountByRecurrence(state, 'yearly')
  return addAmounts(yearlyPartnerManagementPremium, yearlyBrokerCommission)
}

const amountToStringOptions = { addCurrency: true, displayDecimals: 'when-amount-has-cents' } as const
