import { css } from '@emotion/react'
import { amountToString, multiplyByNumber, multiplyByRate, type Amount } from '@orus.eu/amount'
import type { RevenueLimitSubscriptionUiElement } from '@orus.eu/dimensions'
import { PersistentNotification, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../client'
import { ifStateProxy } from '../if-state-proxy'

export const RevenueLimitSubscriptionUiElementBlock = ifStateProxy<RevenueLimitSubscriptionUiElement>(
  function RevenueLimitSubscriptionUiElementBlock({ uiElement, stateProxy }) {
    const dimensions = uiElement.dimensions
    const activitiesRaw = stateProxy.readRequired(uiElement.dimensions.activities)
    const estimatedRevenue = stateProxy.readRequired(dimensions.estimatedRevenue)
    const activitiesDistribution = stateProxy.readRequired(dimensions.activitiesDistribution)

    const [rcdaActivitiesScope] = trpcReact.rcda.getRcdaExclusions.useSuspenseQuery()
    const {
      maxPercentageSubcontractedByActivity,
      maxPercentageSubcontracted,
      maxPercentagePerConstructionSite,
      maxPercentageForMaterialsTrading,
    } = rcdaActivitiesScope.limit

    const activitySharePercentage = (activity: { activity: string; displayName: string }) =>
      activitiesDistribution.type === 'multiple' && activitiesDistribution.distribution[activity.activity]
        ? activitiesDistribution.distribution[activity.activity].sharePercentage / 100
        : 1

    return (
      <PersistentNotification
        variant="info"
        title={`
            Répartition de votre chiffre d'affaires (${amountToString(estimatedRevenue, {
              displayDecimals: false,
              addCurrency: true,
            })})`}
      >
        <ul>
          <li>
            <TextWithAmountWrapper
              amount={multiplyByRate(estimatedRevenue, maxPercentageSubcontracted)}
              text="sous-traités à d’autres entreprises"
            />
          </li>
          {activitiesRaw.map((activity) => (
            <li key={activity.activity}>
              <TextWithAmountWrapper
                amount={multiplyByNumber(
                  multiplyByRate(estimatedRevenue, maxPercentageSubcontractedByActivity),
                  activitySharePercentage(activity),
                )}
                text={`sous-traités à d'autres entreprises en ${activity.displayName}`}
              />
            </li>
          ))}
          <li>
            <TextWithAmountWrapper
              amount={multiplyByRate(estimatedRevenue, maxPercentageSubcontracted)}
              text="en tant que sous-traitant"
            />
          </li>
          <li>
            <TextWithAmountWrapper
              amount={multiplyByRate(estimatedRevenue, maxPercentagePerConstructionSite)}
              text="pour un chantier unique"
            />
          </li>
          <li>
            <TextWithAmountWrapper
              amount={multiplyByRate(estimatedRevenue, maxPercentageForMaterialsTrading)}
              text={'de négoce en matériaux'}
            />
          </li>
        </ul>
      </PersistentNotification>
    )
  },
)

type TextWithAmountWrapperProps = {
  className?: string
  amount: Amount
  text: string
}

const TextWithAmountWrapper = memo<TextWithAmountWrapperProps>(function TextWithAmountWrapper({
  className,
  amount,
  text,
}): JSX.Element {
  const textAmount = `${amountToString(amount, {
    displayDecimals: false,
    addCurrency: true,
  })}`

  return (
    <Text variant="body2">
      Plus de{' '}
      <span
        className={className}
        css={css`
          font-weight: 500;
        `}
      >
        {textAmount}{' '}
      </span>
      {text}
    </Text>
  )
})
