import type { SubscriptionUiElementDisplayValuesSpecs } from '@orus.eu/dimensions'
import { useTranslate } from '@orus.eu/pharaoh'

export function useUiElementTexts(uiElement: SubscriptionUiElementDisplayValuesSpecs):
  | {
      label: string | undefined
      hint: string | undefined
    }
  | undefined {
  const translate = useTranslate()
  return 'displayValues' in uiElement && uiElement.displayValues
    ? {
        label: uiElement.displayValues.label ?? undefined,
        hint: uiElement.displayValues.hint ?? undefined,
      }
    : 'displayKeys' in uiElement && uiElement.displayKeys
      ? {
          label: uiElement.displayKeys.label ? translate(uiElement.displayKeys.label) : undefined,
          hint: uiElement.displayKeys.hint ? translate(uiElement.displayKeys.hint) : undefined,
        }
      : undefined
}
