import { newSubscribable } from '@orus.eu/observable'
import { useCallback } from 'react'
import { useSinglettonState } from './use-singleton-state'

const nextEnabledContributionsSingleton = newSubscribable<{
  [key in NextEnabledContributor]?: boolean
}>({})

export type ValidationEnablementCallback = (from: NextEnabledContributor, nextEnabled: boolean) => void

export function useModularNextEnabled(): {
  nextEnabledByAllContributions: boolean
  registerNextEnabledContribution: ValidationEnablementCallback
} {
  const [nextEnabledContributions, setNextEnabledContributions] = useSinglettonState(nextEnabledContributionsSingleton)

  const registerNextEnabledContribution = useCallback(
    (from: NextEnabledContributor, nextEnabled: boolean) => {
      setNextEnabledContributions((currentContributions) => ({ ...currentContributions, [from]: nextEnabled }))
    },
    [setNextEnabledContributions],
  )

  const nextEnabledByAllContributions = Object.values(nextEnabledContributions).every((value) => value)

  return { nextEnabledByAllContributions, registerNextEnabledContribution }
}

export type NextEnabledContributor = 'email-availability-checker' | 'activities-distribution-checker'
