import type { ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'

export const surfaceMapper: ValidatedTypeMapper<number> = {
  inputType: 'text',
  format: (value) => Math.round(value).toString(10),
  formatPlaceholder: (value) => Math.round(value).toString(10),
  parse: (text) => {
    const number = parseInt(text, 10)
    return Number.isSafeInteger(number) ? success(number) : failure('Saisir un nombre entier de m²')
  },
}
