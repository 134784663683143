import type { DisabledStringDimensionSubscriptionUiElement } from '@orus.eu/dimensions'
import { TextField, TextInputLabelWrapper, useLanguage } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const DisabledStringDimensionSubscriptionUiElementBlock =
  ifStateProxy<DisabledStringDimensionSubscriptionUiElement>(
    function DisabledStringDimensionSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
      const language = useLanguage()
      const value = stateProxy.read(uiElement.dimension)
      return (
        <TextInputLabelWrapper label={uiElement.dimension.displayNames[language]}>
          <TextField size={context === 'backoffice' ? 'small' : 'large'} value={value} />
        </TextInputLabelWrapper>
      )
    },
  )
