import { css } from '@emotion/react'
import { nbsp } from '@orus.eu/char'
import { memo } from 'react'
import { Text, colorTokens, colors, spacing } from '../../../../index.js'
import { ButtonGroup } from '../../../button/button-group.js'
import { CalloutNotificationTemplate } from '../../../callout-notification/common/callout-notification-template.js'
import { Avatar, Button } from '../../../index.js'
import { RowContainer } from '../../../rows/row-container.js'
import { Row } from '../../../rows/row.js'

type Activity = { id: string; name: string }

type ActivitiesSelectorProps = {
  mainActivity: Activity
  complementaryActivities: Activity[]
  maxActivitiesCount: number | undefined
  isJustifyExperienceTextVisible?: boolean
  showMainActivity: boolean

  onAddActivity: () => void
  onRemoveActivity: (activity: Activity) => void
  onMoreDetailsClick?: (activity: Activity) => void

  className?: string
}

export const ActivitiesSelector = memo<ActivitiesSelectorProps>(function ActivitiesSelector(props) {
  const activitiesCount = 1 + props.complementaryActivities.length
  const shouldShowAddButton = props.maxActivitiesCount && activitiesCount < props.maxActivitiesCount

  if (!props.maxActivitiesCount) return undefined

  return (
    <div className={props.className}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing['30']};
        `}
      >
        <RowContainer size={'medium'} variant="spacing">
          {props.showMainActivity ? (
            <Row
              title={props.mainActivity.name}
              titleVariant={'body2'}
              buttonGroup={
                <ButtonGroup>
                  {props.onMoreDetailsClick ? (
                    <Button variant="secondary" onClick={() => props.onMoreDetailsClick!(props.mainActivity)}>
                      Plus de détails
                    </Button>
                  ) : null}
                </ButtonGroup>
              }
            />
          ) : (
            <></>
          )}

          {props.complementaryActivities.map((activity, index) => (
            <Row
              title={activity.name}
              titleVariant={'body2'}
              key={index}
              buttonGroup={
                <ButtonGroup>
                  {props.onMoreDetailsClick ? (
                    <Button variant="secondary" onClick={() => props.onMoreDetailsClick!(activity)}>
                      Plus de détails
                    </Button>
                  ) : null}
                  <Button
                    icon="xmark-solid"
                    variant="text"
                    ariaLabel="Retirer cette activité"
                    size="small"
                    onClick={() => props.onRemoveActivity(activity)}
                  />
                </ButtonGroup>
              }
            />
          ))}
        </RowContainer>
      </div>

      {shouldShowAddButton ? (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: ${spacing['30']};
          `}
        >
          <Button
            icon="plus-solid"
            size="small"
            variant="secondary"
            avatarPosition="left"
            onClick={props.onAddActivity}
            ariaLabel="ajouter-activité"
            css={css`
              margin-left: auto;
            `}
          >
            Ajouter une autre activité
          </Button>
        </div>
      ) : (
        <div
          css={css`
            margin-top: ${spacing[50]};
          `}
        >
          <CalloutNotificationTemplate
            leftAvatar={<Avatar icon={'ban-solid'} />}
            leftAvatarSize="30"
            backgroundColor={colorTokens['color-bg-danger-inverse']}
            textColor={colorTokens['color-text-base-main']}
            accentColor={colorTokens['color-fg-danger']}
          >
            Vous avez atteint le nombre maximum d’activités.
          </CalloutNotificationTemplate>
        </div>
      )}
      {props.isJustifyExperienceTextVisible ? (
        <Text
          variant="body2"
          color={colors.gray[700]}
          css={css`
            margin-top: ${shouldShowAddButton ? spacing['30'] : spacing['70']};
          `}
        >
          Votre expérience devra être justifiée{nbsp}: fiches de paie, factures, certificats de travail, etc.
        </Text>
      ) : null}
    </div>
  )
})
