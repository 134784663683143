import { amountToString, parseAmount, type Amount } from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import type { ValidatedTypeMapper } from '@orus.eu/pharaoh'
import type { Result } from '@orus.eu/result'
import { failure, success } from '@orus.eu/result'
import { AmountFormatter } from '../components/molecules/validated-text-field'

export const amountCustomInputProps = { inputComponent: AmountFormatter }

export const amountMapper: ValidatedTypeMapper<Amount> = {
  inputType: 'text',
  format: (value) => amountToString(value, amountToStringOptions),
  formatPlaceholder: (value) => `€${nbsp}${amountToString(value, amountToStringOptions)}`,
  parse: (text) => {
    const amount = parseAmount(text)
    return amount ? success(amount) : failure('Saisir un montant')
  },
}

export const amountMapperWithNone: ValidatedTypeMapper<Amount | 'none'> = {
  inputType: 'text',
  format: (value) => (value === 'none' ? '' : amountToString(value, amountToStringOptions)),
  formatPlaceholder: (value) =>
    value === 'none' ? `€${nbsp}` : `€${nbsp}${amountToString(value, amountToStringOptions)}`,
  parse: (text): Result<Amount | 'none', string> => {
    if (!text) return success<Amount | 'none'>('none')

    const amount = parseAmount(text)
    return amount ? success(amount) : failure('Saisir un montant')
  },
}

const amountToStringOptions = { displayDecimals: false, addCurrency: false }
