import { css } from '@emotion/react'
import type { SeparatorSubscriptionUiElement } from '@orus.eu/dimensions'
import { colors } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const SeparatorSubscriptionUiElementBlock = ifStateProxy<SeparatorSubscriptionUiElement>(
  function SeparatorSubscriptionUiElementBlock() {
    return (
      <hr
        css={css`
          border: none;
          background-color: ${colors.gray[100]};
          height: 1px;
          width: 100%;
        `}
      />
    )
  },
)
