import type { SubscriptionDocumentType } from '@orus.eu/dimensions'

export function getUploadMySubscriptionFilePath({
  type,
  subscriptionId,
}: {
  subscriptionId: string
  type: SubscriptionDocumentType
}): string {
  return `/upload/${type}/my-subscription/${subscriptionId}`
}

/**
 * For admins anytime
 */
export function getUploadSubscriptionFilePath({
  type,
  subscriptionId,
}: {
  subscriptionId: string
  type: SubscriptionDocumentType
}): string {
  return `/upload/${type}/subscription/${subscriptionId}`
}
