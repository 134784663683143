import type { CallToHonestyBannerSubscriptionUiElement } from '@orus.eu/dimensions'
import { Avatar, emoji, useSkin } from '@orus.eu/pharaoh'
import { CalloutNotificationTemplate } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { memo } from 'react'
import type { SubscriptionElementBlockProps } from '../../subscription-v2-props'

export const CallToHonestyBannerSubscriptionUiElementBlock = memo<
  SubscriptionElementBlockProps<CallToHonestyBannerSubscriptionUiElement>
>(function CallToHonestyBannerSubscriptionUiElementBlock() {
  const skin = useSkin()
  return (
    <CalloutNotificationTemplate
      leftAvatar={<Avatar src={emoji.lightBulb} />}
      leftAvatarSize="30"
      title="Bon à savoir"
      {...skin.subscriptionCalloutColors}
    >
      Cette information est importante pour nous. Votre contrat pourra être annulé si votre déclaration est inexacte.
    </CalloutNotificationTemplate>
  )
})
