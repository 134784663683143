import { Autocomplete, TextField } from '@mui/material'
import { countries, type Countries } from '@orus.eu/countries'
import type { CountrySubscriptionUiElement } from '@orus.eu/dimensions'
import { TextInputLabelWrapper, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, type SyntheticEvent } from 'react'
import { ifStateProxy } from '../if-state-proxy'

const regionNamesInFrench = new Intl.DisplayNames(['fr'], { type: 'region' })

export const CountrySubscriptionUiElementBlock = ifStateProxy<CountrySubscriptionUiElement>(
  function CountrySubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const countryValue = stateProxy.read(dimension)
    const handleChange = useCallback(
      (_: SyntheticEvent<Element, Event>, value: Countries | null) => {
        if (value) {
          stateProxy.write(dimension, value)
        }
      },
      [dimension, stateProxy],
    )

    return (
      <TextInputLabelWrapper label={dimension.displayNames[language]} required={uiElement.required}>
        <Autocomplete
          id="country-select"
          aria-label={dimension.ariaLabels ? dimension.ariaLabels[language] : undefined}
          options={countries}
          getOptionLabel={(option) => regionNamesInFrench.of(option) ?? 'N/A'}
          onChange={handleChange}
          value={countryValue}
          size={context === 'backoffice' ? 'small' : 'medium'}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              placeholder=""
              /**
               * Use the MUI error prop mechanism to show the required state of the field.
               */
              error={uiElement.highlight}
            />
          )}
        />
      </TextInputLabelWrapper>
    )
  },
)
