import type { ContactBannerSubscriptionUiElement } from '@orus.eu/dimensions'
import { ContactBanner } from '@orus.eu/pharaoh/src/components/features/universal-subscription'
import { orusStandardPhone } from '../../../../lib/orus'
import { ifStateProxy } from '../if-state-proxy'

export const ContactBannerSubscriptionUiElementBlock = ifStateProxy<ContactBannerSubscriptionUiElement>(
  function ContactBannerSubscriptionUiElementBlock({ stateProxy, uiElement }) {
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    return <ContactBanner orusContactPhone={orusStandardPhone[operatingZone]} />
  },
)
