import { css } from '@emotion/react'
import { type GuaranteeDetailUiElement, type ProductInformation } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull, TechnicalError } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { GoBackButton, spacing, Text, useScreenType, useTranslate, type ScreenType } from '@orus.eu/pharaoh'
import { GuaranteesTable } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import type { GuaranteesSection } from '@orus.eu/pharaoh/src/components/features/quote-v3/guarantees-table'
import { useNavigate } from '@tanstack/react-router'
import { memo, useEffect, useMemo } from 'react'
import { useNavigateBack } from '../../../../lib/hooks/use-navigate-back'
import { openChat } from '../../../../lib/hubspot-util'
import { orusStandardPhone } from '../../../../lib/orus'
import { GuaranteeModal } from '../../../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeModalDisplayer } from '../../../organisms/guarantee/option-list'
import { useProductInformation } from '../../subscription/shared-use-offer'
import { computeGuaranteesSection } from '../../subscription/utils'
import { ifStateProxy } from '../if-state-proxy'

export const GuaranteeDetailSubscriptionElementUiBlock = ifStateProxy<GuaranteeDetailUiElement>(
  function GuaranteeDetailSubscriptionElementUiBlock({ uiElement, stateProxy, detail, subscriptionId }) {
    const screenType = useScreenType()
    const navigateBack = useNavigateBack()
    const navigate = useNavigate()

    if (!detail) {
      throw new TechnicalError(
        'The GuaranteeDetailUiElement can only be used in the detail section of a type "guaratee"',
      )
    }

    const offerInformation = stateProxy.readRequired(uiElement.dimensions.offerInformation)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const productName = checkDefinedAndNotNull(detail).id

    const productInformation = offerInformation.products.find((product) => product.name === productName)

    useEffect(() => {
      if (!productInformation)
        void navigate({ to: '/subscribe/$subscriptionId/$stepId', params: { subscriptionId, stepId: 'quote' } })
    }, [navigate, productInformation, subscriptionId])

    const guaranteesSection: GuaranteesSection[] = useMemo(() => {
      if (!productInformation) return []
      return computeGuaranteesSection(productInformation.guarantees, productInformation.offers)
    }, [productInformation])

    //When productInformation is undefined, the user is redirected to the quote page
    if (!productInformation) return <></>

    return (
      <GuaranteeDetail
        productInformation={productInformation}
        operatingZone={operatingZone}
        navigateBack={navigateBack}
        guaranteesSection={guaranteesSection}
        screenType={screenType}
      />
    )
  },
)

type GuaranteeDetailProps = {
  productInformation: ProductInformation
  navigateBack: () => void
  guaranteesSection: GuaranteesSection[]
  screenType: ScreenType
  operatingZone: OperatingZone
}

const GuaranteeDetail = memo<GuaranteeDetailProps>(function GuaranteeDetail(props) {
  const translate = useTranslate()
  const { productInformation, navigateBack, guaranteesSection, screenType, operatingZone } = props

  const {
    selectedGuaranteeState,
    selectedOptionState,
    onGuaranteeClick,
    onInfoOptionClick,
    options,
    perOptionSubOptions,
  } = useProductInformation(productInformation)

  const [selectedGuarantee] = selectedGuaranteeState
  const [selectedOption] = selectedOptionState

  return (
    <>
      {screenType === 'mobile' ? <GoBackButton goBack={navigateBack} size="medium" /> : <></>}
      <Text
        variant="h4"
        css={css`
          margin-top: ${spacing[50]};
          margin-bottom: ${spacing[80]};
        `}
      >
        {translate('guarantees_table')}
      </Text>
      <GuaranteesTable
        guaranteesSection={guaranteesSection}
        options={options}
        perOptionSubOptions={perOptionSubOptions}
        variant={screenType}
        includedGuaranteesCount={productInformation.guarantees.length}
        onContactClick={openChat}
        onGuaranteeClick={onGuaranteeClick}
        onInfoOptionClick={onInfoOptionClick}
        quotePageType="static"
        isMuta={productInformation.product === 'muta'}
        orusContactPhone={orusStandardPhone[operatingZone]}
      />
      {selectedGuarantee ? <GuaranteeModal guarantee={selectedGuarantee} onClose={navigateBack} /> : <></>}
      {selectedOption ? (
        <OptionalGuaranteeModalDisplayer
          optionalGuarantee={selectedOption}
          onClose={navigateBack}
          addButton={undefined}
          quotePageType="static"
        />
      ) : (
        <></>
      )}
    </>
  )
})
