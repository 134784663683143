import { isActivity, type ActivityInput, type GroupsCategory } from '@orus.eu/activity'
import { activityRelatedInputDimensionsToReset, type ActivitySearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { selfOnboardingGroupMappingLoader } from '../../../../lib/group-mapping-util'
import { ActivitySearchField } from '../../../organisms/activity-search-field'
import { ifStateProxy } from '../if-state-proxy'

export const ActivitySearchSubscriptionUiElementBlock = ifStateProxy<ActivitySearchSubscriptionUiElement>(
  function ActivitySearchSubscriptionUiElementBlock(props) {
    const { uiElement, handleSubmit, stateProxy } = props

    const activity = stateProxy.read<ActivityInput>(uiElement.dimensions.activity)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    const handleActivitySelected = useAsyncCallback(
      async (group: string) => {
        if (!group) return
        const groupDescription = await selfOnboardingGroupMappingLoader.getGroupMapping({ group, operatingZone })
        if (!groupDescription) {
          alert('Nous ne couvrons pas encore cette activité')
          return
        }
        const { mainActivityId } = groupDescription
        if (!isActivity(mainActivityId)) {
          throw new TechnicalError('Invalid activity selected in category search', {
            context: { message: mainActivityId },
          })
        }

        handleSubmit(undefined, {
          [uiElement.dimensions.activity.name]: { activity: mainActivityId, displayName: group },
          [uiElement.dimensions.searchCategory.name]: 'bypassed',
          ...activityRelatedInputDimensionsToReset,
        })
      },
      [handleSubmit, operatingZone, uiElement],
    )

    const handleCategorySelected = useAsyncCallback(
      async (category: GroupsCategory) => {
        handleSubmit(undefined, {
          [uiElement.dimensions.activity.name]: null,
          [uiElement.dimensions.complementaryActivities.name]: [],
          [uiElement.dimensions.searchCategory.name]: category,
        })
      },
      [handleSubmit, uiElement.dimensions],
    )

    const handleActivityNotFound = useCallback(() => {
      window.location.href = 'https://www.orus.eu/subscriptions/trouver-mon-activite'
    }, [])

    return (
      <ActivitySearchField
        operatingZone={operatingZone}
        defaultValue={activity?.displayName}
        onActivitySelected={handleActivitySelected}
        onActivityNotFound={handleActivityNotFound}
        onCategorySelected={handleCategorySelected}
        autoFocus
      />
    )
  },
)
