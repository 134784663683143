import { css } from '@emotion/react'
import type { CalendarDateSubscriptionUiElement } from '@orus.eu/dimensions'
import { Datepicker, TextInputLabelWrapper, useLanguage, useScreenType } from '@orus.eu/pharaoh'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { ifStateProxy } from '../if-state-proxy'

export const CalendarDateSubscriptionUiElementBlock = ifStateProxy<CalendarDateSubscriptionUiElement>(
  function CalendarDateSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const screenType = useScreenType()
    const handleChange = stateProxy.useWrite(uiElement.dimensions.value)

    return (
      <TextInputLabelWrapper
        label={uiElement.dimensions.value.displayNames[language]}
        labelTextVariant={uiElement.size === 'large' ? 'subtitle2' : undefined}
        infoTooltip={uiElement.dimensions.value.hints ? uiElement.dimensions.value.hints[language] : undefined}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimensions.value.tags?.[0]} /> : <></>}
        required={uiElement.required}
      >
        <Datepicker
          size={context === 'backoffice' ? 'small' : 'large'}
          calendarDate={stateProxy.read(uiElement.dimensions.value)}
          minDate={uiElement.dimensions.minValue ? stateProxy.read(uiElement.dimensions.minValue) : undefined}
          maxDate={uiElement.dimensions.maxValue ? stateProxy.read(uiElement.dimensions.maxValue) : undefined}
          onChange={handleChange}
          css={css`
            ${screenType === 'desktop' ? 'max-width: 400px;' : ''}
          `}
          /**
           * Use the MUI error prop mechanism to show the required state of the field.
           */
          error={uiElement.highlight}
        />
      </TextInputLabelWrapper>
    )
  },
)
