import { mutaActualCosts, mutaOneHundredPct, mutaYes, type MutaGuarantees } from '../muta-guarantees.js'

export const mutaPriv5Guarantees: MutaGuarantees = {
  HO_FDS_CO: mutaActualCosts,
  HO_FDS_NCO: mutaOneHundredPct,
  HO_HO: {
    type: 'optamThreeYears',
    PA: {
      FY: { type: 'percentage', percentage: 200 },
      SY: { type: 'percentage', percentage: 200 },
      TY: { type: 'percentage', percentage: 200 },
    },
    PNA: { type: 'percentage', percentage: 155 },
  },
  HO_HO_HO: mutaOneHundredPct,
  HO_CH_FY: { type: 'amount', amount: 70 },
  HO_CH_SY: { type: 'amount', amount: 75 },
  HO_CH_TY: { type: 'amount', amount: 80 },
  HO_SA: { type: 'amount', amount: 15 },
  HO_FJ: mutaActualCosts,
  HO_CO_HO: { type: 'amount', amount: 20 },
  HO_HAD: mutaOneHundredPct,
  MD_MN: { type: 'amount', amount: 120 },
  MD_PREV: mutaOneHundredPct,
  MD_PROT: mutaOneHundredPct,
  SC_HM_MG: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 175 },
    PNA: { type: 'percentage', percentage: 155 },
  },
  SC_HM_MS: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 175 },
    PNA: { type: 'percentage', percentage: 155 },
  },
  SC_HM_IM: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 175 },
    PNA: { type: 'percentage', percentage: 155 },
  },
  SC_HM_ATM: {
    type: 'optam',
    PA: { type: 'percentage', percentage: 175 },
    PNA: { type: 'percentage', percentage: 155 },
  },
  SC_HP: mutaOneHundredPct,
  SC_LAB: mutaOneHundredPct,
  SC_TRANS: mutaOneHundredPct,
  SC_MEDIC_PEC: mutaOneHundredPct,
  SC_PAPP: mutaOneHundredPct,
  SC_MEDIC_NPEC: { type: 'amount', amount: 30 },
  DEN_HPS: { type: 'percentage', percentage: 175 },
  DEN_NPC_RB: { type: 'amount', amount: 250 },
  DEN_ORT_RB_FY: mutaOneHundredPct,
  DEN_ORT_RB_SY: { type: 'percentage', percentage: 125 },
  DEN_ORT_RB_TY: { type: 'percentage', percentage: 150 },
  DEN_PROT_RB_FY: { type: 'percentage', percentage: 225 },
  DEN_PROT_RB_SY: { type: 'percentage', percentage: 250 },
  DEN_PROT_RB_TY: { type: 'percentage', percentage: 275 },
  DEN_PLAF_REMB_FY: { type: 'amount', amount: 1000 },
  DEN_PLAF_REMB_SY: { type: 'amount', amount: 1500 },
  DEN_PLAF_REMB_TY: { type: 'amount', amount: 1500 },
  OPT_SIMP_MONT_FY: { type: 'amount', amount: 250 },
  OPT_SIMP_MONT_SY: { type: 'amount', amount: 300 },
  OPT_SIMP_MONT_TY: { type: 'amount', amount: 300 },
  OPT_COMP_MONT_FY: { type: 'amount', amount: 350 },
  OPT_COMP_MONT_SY: { type: 'amount', amount: 400 },
  OPT_COMP_MONT_TY: { type: 'amount', amount: 400 },
  OPT_T_COMP_MONT_FY: { type: 'amount', amount: 350 },
  OPT_T_COMP_MONT_SY: { type: 'amount', amount: 400 },
  OPT_T_COMP_MONT_TY: { type: 'amount', amount: 400 },
  OPT_SIMP_COMP_FY: { type: 'amount', amount: 350 },
  OPT_SIMP_COMP_SY: { type: 'amount', amount: 400 },
  OPT_SIMP_COMP_TY: { type: 'amount', amount: 400 },
  OPT_SIMP_T_COMP_FY: { type: 'amount', amount: 350 },
  OPT_SIMP_T_COMP_SY: { type: 'amount', amount: 400 },
  OPT_SIMP_T_COMP_TY: { type: 'amount', amount: 400 },
  OPT_COMP_T_COMP_FY: { type: 'amount', amount: 350 },
  OPT_COMP_T_COMP_SY: { type: 'amount', amount: 400 },
  OPT_COMP_T_COMP_TY: { type: 'amount', amount: 400 },
  OPT_FORF_LENT: { type: 'amount', amount: 125 },
  AUD_APP_PROT: mutaOneHundredPct,
  AUD_FORF_AUD: { type: 'amount', amount: 400 },
  AUD_ACCESS: mutaOneHundredPct,
  CUR_HO_CONV: { type: 'percentage', percentage: 175 },
  CUR_HO_HCONV: { type: 'percentage', percentage: 155 },
  CUR_FRAIS_ETAB: { type: 'percentage', percentage: 175 },
  CUR_TRANS_HEB: { type: 'amount', amount: 175 },
  SER_TIERS_PAY: mutaYes,
  SER_ASSIST: mutaYes,
  SER_AUTO_REMB: mutaYes,
  ASS_TELE_CONSULT_MEDI: { type: 'sessionPerInsured', session: 10 },
  ASS_COACH_WELLNESS: { type: 'session', session: 4 },
  ASS_ACCOMP_PSY: { type: 'session', session: 4 },
  ASS_HOME_HELP_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_HOME_HELP_TO: {
    type: 'amount',
    amount: 500,
  },
  ASS_TRANS_TAXI_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_TRANS_TAXI_TO: {
    type: 'amount',
    amount: 500,
  },
}
