import { z } from 'zod'

export const uploadFailedSchema = z.object({
  ok: z.literal(false),
  status: z.number(),
  reason: z.string(),
})

export const uploadSuccessfulSchema = z.object({
  ok: z.literal(true),
  fileName: z.string(),
  fileId: z.string(),
  timestamp: z.number(),
  mimeType: z.string(),
})

export const uploadStatusSchema = z.union([uploadFailedSchema, uploadSuccessfulSchema])

export type UploadSuccessful = z.infer<typeof uploadSuccessfulSchema>

export type UploadStatus = z.infer<typeof uploadStatusSchema>
