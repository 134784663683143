import { css } from '@emotion/react'
import type { TextSubscriptionUiElement } from '@orus.eu/dimensions'
import { spacing, Text, useTranslate } from '@orus.eu/pharaoh'
import { memo, type NamedExoticComponent } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const TextSubscriptionUiElementBlock = ifStateProxy<TextSubscriptionUiElement>(
  function TextSubscriptionUiElementBlock({ uiElement }) {
    const translate = useTranslate()
    const Component = components[uiElement.type]
    const text = 'textKey' in uiElement ? translate(uiElement.textKey) : uiElement.text
    return <Component text={text} />
  },
)

const Subtitle = memo<{ text: string }>(function Subtitle({ text }) {
  return (
    <Text
      css={css`
        margin-top: ${spacing[70]};
      `}
      variant="subtitle2"
    >
      {text}
    </Text>
  )
})

const components: Record<TextSubscriptionUiElement['type'], NamedExoticComponent<{ text: string }>> = {
  subtitle: Subtitle,
}
