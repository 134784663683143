import {
  localizeAmountAndRecurrence,
  type PolicyGuarantee,
  type PolicyOptionalGuarantee,
  type ProductInformation,
} from '@orus.eu/dimensions'
import { useLanguage } from '@orus.eu/pharaoh'
import type { Guarantee, Option } from '@orus.eu/pharaoh/src/components/features/quote-v3/types'
import type { Language } from '@orus.eu/translations'
import { useCallback, useMemo, useState, type Dispatch, type SetStateAction } from 'react'
import { guaranteeImages } from '../../organisms/guarantee/guarantee-images'
import { optionalGuaranteeImages } from '../../organisms/guarantee/optional-guarantee-images'
import { computeDeductibleToDisplay } from './utils'

type OfferHook = {
  guarantees: Guarantee[]
  options: Option[]
  perOptionSubOptions: Record<string, Option[]>
  onGuaranteeClick: (guarantee: Guarantee) => void
  onInfoOptionClick: (option: Option) => void
  selectedGuaranteeState: [PolicyGuarantee, Dispatch<SetStateAction<PolicyGuarantee>>]
  selectedOptionState: [PolicyOptionalGuarantee, Dispatch<SetStateAction<PolicyOptionalGuarantee>>]
}

export function useProductInformation(
  productInformation: Pick<ProductInformation, 'guarantees' | 'optionalGuarantees' | 'product'>,
): OfferHook {
  const language = useLanguage()
  const guarantees: Guarantee[] = useMemo(() => {
    return productInformation.guarantees
      .filter((g) => g.priority === 'P1')
      .map((g) => {
        return {
          image: guaranteeImages[g.type],
          type: g.type,
          name: g.name,
          shortText: g.shortText,
          deductible: g.deductibles ? computeDeductibleToDisplay(g.deductibles) : undefined,
          ceiling: {
            amount: g.guaranteeLimits[0].limit ?? 'Aucun',
            locked: g.guaranteeLimits[0].isGuaranteeFixed,
          },
        }
      })
  }, [productInformation.guarantees])

  const selectedGuaranteeState = useState<PolicyGuarantee>(productInformation.guarantees[0])

  const onGuaranteeClick = useCallback(
    (guarantee: Guarantee) => {
      const offerGuarantee = productInformation.guarantees.find((g) => g.type === guarantee.type)
      if (!offerGuarantee) return
      const [, setSelectedGuarantee] = selectedGuaranteeState
      setSelectedGuarantee(offerGuarantee)
      window.location.hash = `guarantee-${offerGuarantee.type}`
    },
    [productInformation.guarantees, selectedGuaranteeState],
  )

  const selectedOptionState = useState<PolicyOptionalGuarantee>(productInformation.optionalGuarantees[0])

  const onInfoOptionClick = useCallback(
    (option: Option) => {
      const offerOption = productInformation.optionalGuarantees.find(
        (opt) => opt.type === option.type || opt.children?.some((child) => child.type === option.type),
      )
      if (!offerOption) return
      const [, setSelectedOption] = selectedOptionState
      setSelectedOption(offerOption.children?.find((child) => child.type === option.type) ?? offerOption)
      window.location.hash = `option-${option.type}`
    },
    [productInformation.optionalGuarantees, selectedOptionState],
  )

  const options: Option[] = useMemo(() => {
    return productInformation.optionalGuarantees.flatMap((optionalGuarantee) => {
      return [optionalGuarantee].map(mapOption(language))
    })
  }, [productInformation.optionalGuarantees, language])

  const perOptionSubOptions = options.reduce(
    (acc, option) => {
      if (option.selected && option.children?.length && option.children.length > 0) {
        acc[option.name] = option.children
      }

      return acc
    },
    {} as Record<string, Option[]>,
  )

  return {
    guarantees,
    selectedGuaranteeState,
    selectedOptionState,
    onGuaranteeClick,
    onInfoOptionClick,
    options,
    perOptionSubOptions,
  }
}

function mapOption(language: Language): (guarantee: PolicyOptionalGuarantee) => Option {
  return (guarantee: PolicyOptionalGuarantee) => {
    return {
      image: optionalGuaranteeImages[guarantee.type],
      type: guarantee.type,
      name: guarantee.name,
      shortText: guarantee.shortText,
      price: guarantee.price ? localizeAmountAndRecurrence(guarantee.price, language) : undefined,
      selected: guarantee.isActive,
      recommended: guarantee.isRecommanded,
      deductible: computeDeductibleToDisplay(guarantee.deductibles),
      ceiling: {
        amount:
          guarantee.guaranteeLimit.limits.length > 0 && guarantee.guaranteeLimit.limits[0].limit
            ? guarantee.guaranteeLimit.limits[0].limit
            : 'Aucun',
        locked: guarantee.guaranteeLimit.limits.length > 0 && guarantee.guaranteeLimit.limits[0].isGuaranteeFixed,
      },
      children: guarantee.children?.map(mapOption(language)),
    }
  }
}
