import styled from '@emotion/styled'
import {
  type ActivableProductInformationId,
  type PossibleOffer,
  type PossibleOffers,
  type ProductIndicatorUiElement,
} from '@orus.eu/dimensions'
import {
  AvatarDecorative,
  Button,
  FlexColumn,
  FlexRow,
  Text,
  colorTokens,
  shadow,
  spacing,
  useDialogVisibility,
  useScreenVariant,
  useTranslate,
} from '@orus.eu/pharaoh'
import { ProductDetailsDialog } from '@orus.eu/pharaoh/src/components/features/universal-subscription/product-selector/product-details-modal'
import { useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'
import type { StateProxy } from '../subscription-v2-props'
import { productDetailsData, productsAvatarConfigurations } from './quote-page-body-element/product-information-util'
import { SeparatorSubscriptionUiElementBlock } from './separator-element'

export const ProductIndicatorUiElementBlock = ifStateProxy<ProductIndicatorUiElement>(
  function ProductIndicatorUiElementBlock({ stateProxy, uiElement, ...props }) {
    const translate = useTranslate()
    const productMatches = Array.isArray(uiElement.product) ? uiElement.product : [uiElement.product]
    const isMobile = useScreenVariant() === 'mobile'
    const possibleOffers = stateProxy.readRequired(uiElement.dimensions.possibleOffersDimension)

    const { recommendedOffers } = useOfferLists(possibleOffers)
    const productName = productMatches.find((match) => recommendedOffers.find(({ id }) => id === match))

    const { show, hide, visible } = useDialogVisibility(productName ?? 'this-value-cannot-appear')

    if (!productName) return <></>

    const { readSelectedState } = productSelectionFacades[productName]

    const isProductSelected = !!readSelectedState(stateProxy, uiElement).value

    const details = Object.keys(productDetailsData).includes(productName) ? productDetailsData[productName] : null
    const avatar = productsAvatarConfigurations[productName]

    if (!isProductSelected) return <></>
    if (!details) return <></>

    return (
      <FlexColumn gap={spacing[70]}>
        <ProductIndicatorContainer>
          <AvatarDecorative size="60" {...avatar} />
          <FlexColumn gap={spacing[20]}>
            <Text variant="subtitle2">{details.name}</Text>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              {translate('subscription_funnel_product_indicator_subtitle')}
            </Text>
          </FlexColumn>
          <Button variant="text" onClick={show}>
            {translate('subscription_funnel_product_indicator_learn_more')}
          </Button>
        </ProductIndicatorContainer>
        <SeparatorSubscriptionUiElementBlock
          stateProxy={stateProxy}
          uiElement={{ ...uiElement, type: 'separator' }}
          {...props}
        />
        {details !== null && visible ? (
          <ProductDetailsDialog
            productId={details.name}
            hide={hide}
            fullWidth={isMobile}
            avatar={avatar}
            details={details}
            showGuaranteeAndOptions
          />
        ) : null}
      </FlexColumn>
    )
  },
)

const ProductIndicatorContainer = styled(FlexRow)`
  display: flex;
  margin: 0;
  padding: ${spacing['60']} ${spacing['70']};
  align-items: center;
  align-content: center;
  gap: 16px ${spacing['50']};
  align-self: stretch;
  flex-wrap: wrap;

  border-radius: ${spacing['40']};
  border: 1px solid ${colorTokens['color-stroke-base']};
  background: ${colorTokens['color-bg-base-normal']};

  /* Shadow/bottom/20 */
  box-shadow: ${shadow.bottom[20]};

  & > ${FlexColumn} {
    margin: 0 auto 0 0;
  }
`

type OfferWithId = PossibleOffer & { id: ActivableProductInformationId }

function useOfferLists(possibleOffers: PossibleOffers): {
  recommendedOffers: Array<OfferWithId>
  otherOffers: Array<OfferWithId>
} {
  return useMemo(() => {
    const possibleOffersWithIds = Object.entries(possibleOffers).map(([id, possibleOffer]) => ({
      ...possibleOffer,
      id: id as ActivableProductInformationId,
    }))
    return {
      recommendedOffers: possibleOffersWithIds.filter((possibleOffer) => possibleOffer.isRecommended),
      otherOffers: possibleOffersWithIds.filter((possibleOffer) => !possibleOffer.isRecommended),
    }
  }, [possibleOffers])
}

type ValueDimensionName = { value: boolean | undefined; dimensionName: string }

const productSelectionFacades: {
  [key in ActivableProductInformationId]: {
    readSelectedState: (stateProxy: StateProxy, uiElement: ProductIndicatorUiElement) => ValueDimensionName
  }
} = {
  mrpw: {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.mrpwSelectedDimension),
        dimensionName: uiElement.dimensions.mrpwSelectedDimension.name,
      }
    },
  },
  mrph: {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.mrphSelectedDimension),
        dimensionName: uiElement.dimensions.mrphSelectedDimension.name,
      }
    },
  },
  'rc-pro': {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.rcphSelectedDimension),
        dimensionName: uiElement.dimensions.rcphSelectedDimension.name,
      }
    },
  },
  muta: {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.mutaSelectedDimension),
        dimensionName: uiElement.dimensions.mutaSelectedDimension.name,
      }
    },
  },
  rcda: {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.rcdaSelectedDimension),
        dimensionName: uiElement.dimensions.rcdaSelectedDimension.name,
      }
    },
  },
  'rcph-tdmi': {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.rcphOptionTdmiDimension),
        dimensionName: uiElement.dimensions.rcphOptionTdmiDimension.name,
      }
    },
  },
  'rcph-cyber': {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.rcphOptionCyberDimension),
        dimensionName: uiElement.dimensions.rcphOptionCyberDimension.name,
      }
    },
  },
  'es-rcph': {
    readSelectedState(stateProxy: StateProxy, uiElement: ProductIndicatorUiElement): ValueDimensionName {
      return {
        value: stateProxy.read(uiElement.dimensions.esRcphSelectedDimension),
        dimensionName: uiElement.dimensions.esRcphSelectedDimension.name,
      }
    },
  },
}
