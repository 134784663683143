import type { SiretSubscriptionUiElement } from '@orus.eu/dimensions'
import { CheckboxContainer, useLanguage, ValidatedTextFieldFormField, type ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { parseSiret, type JsonableSiretData } from '@orus.eu/siret'
import { useCallback, useEffect } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const SiretSubscriptionUiElementBlock = ifStateProxy<SiretSubscriptionUiElement>(
  function SiretSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const {
      siret: siretDimension,
      companyIdNumber: companyIdNumberDimension,
      companyInCreationAllowed: companyInCreationAllowedDimension,
    } = uiElement.dimensions
    const value = stateProxy.read(siretDimension)
    const companyInCreationAllowed = stateProxy.read(companyInCreationAllowedDimension)
    const handleChange = useCallback(
      (value: JsonableSiretData | undefined) => {
        stateProxy.write(siretDimension, value ?? null)
        const siren = value?.type === 'provided' ? value.siret.substring(0, 9) : undefined
        stateProxy.write(companyIdNumberDimension, siren ? { type: 'inferred', siren } : null)
      },
      [companyIdNumberDimension, siretDimension, stateProxy],
    )
    const handleCheckboxChange = useCallback(
      (checked: boolean) => {
        stateProxy.write(siretDimension, checked ? { type: 'later' } : null)
        stateProxy.write(companyIdNumberDimension, checked ? { type: 'later' } : null)
      },
      [companyIdNumberDimension, siretDimension, stateProxy],
    )

    useEffect(() => {
      if (!companyInCreationAllowed && value?.type === 'later') {
        handleChange(undefined)
      }
    }, [companyInCreationAllowed, handleChange, value])

    const siretLater = value?.type === 'later'
    return (
      <div>
        <ValidatedTextFieldFormField<JsonableSiretData | undefined>
          disabled={siretLater}
          size={context === 'backoffice' ? 'small' : 'large'}
          label={uiElement.dimensions.siret.displayNames[language]}
          value={value}
          placeholder={
            siretLater ? undefined : siretDimension.placeholders ? siretDimension.placeholders[language] : undefined
          }
          onChange={handleChange}
          mapper={siretMapper}
          infoTooltip={siretDimension.hints ? siretDimension.hints[language] : undefined}
          required={uiElement.required}
          highlight={uiElement.highlight}
        />
        {companyInCreationAllowed ? (
          <CheckboxContainer checked={siretLater} onChange={handleCheckboxChange} size="small">
            SIRET en cours d’obtention
          </CheckboxContainer>
        ) : (
          <></>
        )}
      </div>
    )
  },
)

const siretMapper: ValidatedTypeMapper<JsonableSiretData | undefined> = {
  inputType: 'text',
  format: (value) => (!value || value.type === 'later' ? '' : value.siret),
  formatPlaceholder: (value) => (!value || value.type === 'later' ? '' : value.siret),
  parse: (text) => {
    text = text.trim()
    if (text === '') return success(undefined)
    const siret = parseSiret(text)
    return siret ? success({ type: 'provided', siret }) : failure('Entrer les 14 chiffres de votre SIRET')
  },
}
