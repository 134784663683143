import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToNumber } from '@orus.eu/amount'
import {
  getCommonClientAttributes,
  getEffectiveYearlyTotalPremium,
  type CoverageInvoicingItemInputState,
  type ProductInformation,
  type QuoteOfferInformation,
  type QuotePageBodySubscriptionUiElement,
} from '@orus.eu/dimensions'
import { desktopMediaQuery, spacing, Text } from '@orus.eu/pharaoh'
import { HelpBlock } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import { useSearch } from '@tanstack/react-router'
import { useEffect, useMemo, useState } from 'react'
import { openChat } from '../../../../../lib/hubspot-util'
import { orusStandardPhone } from '../../../../../lib/orus'
import { sendMessage } from '../../../../../lib/tracking/tracking'
import { ifStateProxy } from '../../if-state-proxy'
import { ProductOptionsList } from './product-options-list'
import { ProductQuoteCard } from './product-quote-card'

export const QuotePageBodyElementBlock = ifStateProxy<QuotePageBodySubscriptionUiElement>(
  function QuotePageBodyElementBlock({ uiElement, stateProxy, subscriptionId, versionedSubscriptionId, context }) {
    const offerInformation = stateProxy.readRequired(uiElement.dimensions.offerInformation)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const [activity] = useState(stateProxy.readRequired(uiElement.tracking.dimensions.activity))
    const [riskCarrierProducts] = useState(stateProxy.readRequired(uiElement.tracking.dimensions.riskCarrierProducts))
    const [yearlyTotalPremium] = useState(
      amountToNumber(getEffectiveYearlyTotalPremium(stateProxy.readRequired(uiElement.tracking.dimensions.quote))),
    )

    const coverageInvoicingItemInputState: CoverageInvoicingItemInputState = {
      mrphQuoteV2: stateProxy.read(uiElement.dimensions.mrphQuoteV2),
      rcphQuoteV2: stateProxy.read(uiElement.dimensions.rcphQuoteV2),
      mrphSelected: stateProxy.read(uiElement.dimensions.mrphSelected),
      rcphSelected: stateProxy.read(uiElement.dimensions.rcphSelected),
      mrpwQuote: stateProxy.read(uiElement.dimensions.mrpwQuote),
      rcdaQuote: stateProxy.read(uiElement.dimensions.rcdaQuote),
      mutaQuote: stateProxy.read(uiElement.dimensions.mutaQuote),
      mrpwSelected: stateProxy.read(uiElement.dimensions.mrpwSelected),
      rcdaSelected: stateProxy.read(uiElement.dimensions.rcdaSelected),
      mutaSelected: stateProxy.read(uiElement.dimensions.mutaSelected),
      paymentRecurrence: stateProxy.read(uiElement.dimensions.paymentRecurrence),
      esRcphQuote: stateProxy.read(uiElement.dimensions.esRcphQuote),
      esRcphSelected: stateProxy.read(uiElement.dimensions.esRcphSelected),
    }

    const { firstProducts, otherProducts } = useProductInformationSplit(offerInformation)

    const searchParams = useSearch({ strict: false })

    useEffect(() => {
      if (!uiElement.sendTrackingEvents) return
      const isLandingPage = searchParams.arrives_receive_quote
      sendMessage({
        event: 'quote_loaded',
        activity_name: activity.displayName,
        page_category: 'subscription_form',
        policy_type: riskCarrierProducts.join(','),
        subscription_id: subscriptionId,
        yearly_total_premium: yearlyTotalPremium,
        is_quote_landing_page: isLandingPage ? 'yes' : 'no',
        ...getCommonClientAttributes(riskCarrierProducts),
      })
    }, [
      activity.displayName,
      riskCarrierProducts,
      searchParams,
      subscriptionId,
      versionedSubscriptionId,
      yearlyTotalPremium,
      uiElement.sendTrackingEvents,
    ])

    return (
      <QuotePageBodyContainer>
        {firstProducts.map((productInformation) => (
          <ProductQuoteCard
            key={productInformation.product}
            productInformation={productInformation}
            stateProxy={stateProxy}
            uiElement={uiElement}
            subscriptionId={subscriptionId}
            context={context}
            embedGuaranteesTable={context === 'backoffice'}
            coverageInvoicingItemInputState={coverageInvoicingItemInputState}
          />
        ))}
        {context === 'selfonboarding' ? (
          <HelpBlock onContactClick={openChat} orusContactPhone={orusStandardPhone[operatingZone]} />
        ) : (
          <></>
        )}
        {otherProducts.map((productInformation) => (
          <ProductQuoteCard
            key={productInformation.product}
            productInformation={productInformation}
            stateProxy={stateProxy}
            uiElement={uiElement}
            subscriptionId={subscriptionId}
            context={context}
            embedGuaranteesTable={context === 'backoffice'}
            coverageInvoicingItemInputState={coverageInvoicingItemInputState}
          />
        ))}

        {context === 'backoffice' ? (
          <>
            <Text variant="subtitle2">Options supplémentaires</Text>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[30]};
              `}
            >
              {[...firstProducts, ...otherProducts].map((productInformation) => (
                <ProductOptionsList
                  key={productInformation.product}
                  productInformation={productInformation}
                  stateProxy={stateProxy}
                  uiElement={uiElement}
                  subscriptionId={subscriptionId}
                />
              ))}
            </div>
          </>
        ) : null}
      </QuotePageBodyContainer>
    )
  },
)

const QuotePageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};

  ${desktopMediaQuery} {
    /* This magical value is given by Design team to prevent
     * the quote page to grow too big for small screens */
    max-width: 632px;
  }
`

function useProductInformationSplit(offerInformation: QuoteOfferInformation): {
  firstProducts: ProductInformation[]
  otherProducts: ProductInformation[]
} {
  const products = offerInformation.products
  return useMemo(() => {
    const rcPro = products.find((product) => product.product === 'rc-pro')
    if (rcPro) {
      const pj = products.find((product) => product.product === 'rcph-pj')
      if (pj) {
        return {
          firstProducts: [rcPro, pj],
          otherProducts: products.filter((product) => product !== rcPro && product !== pj),
        }
      }
    }

    const rcda = products.find((product) => product.product === 'rcda')
    if (rcda) {
      const rcPro = products.find((product) => product.product === 'rcda-rc-pro')
      if (rcPro) {
        return {
          firstProducts: [rcda, rcPro],
          otherProducts: products.filter((product) => product !== rcda && product !== rcPro),
        }
      }
    }

    const [firstProduct, ...otherProducts] = products
    return { firstProducts: firstProduct ? [firstProduct] : [], otherProducts }
  }, [products])
}
