import type { ReadonlyCurrentCountrySubscriptionUiElement } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { TextField, TextInputLabelWrapper, useTranslate } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const ReadonlyCurrentCountrySubscriptionUiElementBlock =
  ifStateProxy<ReadonlyCurrentCountrySubscriptionUiElement>(function ReadonlyCurrentCountrySubscriptionUiElementBlock({
    uiElement,
    context,
    stateProxy,
  }) {
    const translate = useTranslate()
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    return (
      <TextInputLabelWrapper label={translate('country')}>
        <TextField
          disabled
          size={context === 'backoffice' ? 'small' : 'large'}
          value={operatingZoneCountryName[operatingZone]}
        />
      </TextInputLabelWrapper>
    )
  })

const operatingZoneCountryName: Record<OperatingZone, string> = {
  fr: 'France',
  es: 'España',
}
