import { css } from '@emotion/react'
import { PARIS, calendarDateToString } from '@orus.eu/calendar-date'
import type { Countries } from '@orus.eu/countries'
import {
  mutaSocialSecurityRegimeEntries,
  type FinalizeBeneficiariesSubscriptionUiElement,
  type MutaBeneficiary,
  type MutaBeneficiaryType,
} from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import {
  Avatar,
  Chip,
  RowButtonV2,
  RowContainerV2,
  colors,
  spacing,
  useDialogVisibility,
  useLanguage,
} from '@orus.eu/pharaoh'
import { MemberModal } from '@orus.eu/pharaoh/src/components/features/health-cover'
import child from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/child.png'
import kiss from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/kiss.png'
import personWhiteHair from '@orus.eu/pharaoh/src/components/features/health-cover/emoji/person-white-hair.png'
import { memo, useCallback, useMemo, useState, type FunctionComponent } from 'react'
import { ifStateProxy } from '../../if-state-proxy'
import { isMemberCompleted } from './utils'

export type FinalizeBeneficiaryMemberInfo = {
  beneficiaryToUpdate: MutaBeneficiary
  index: number
  referenceSocialSecurityNumbers?: string[]
  otherBeneficiaries: MutaBeneficiary[]
}

export const FinalizeBeneficiariesSubscriptionUiElementBlock = ifStateProxy<FinalizeBeneficiariesSubscriptionUiElement>(
  function FinalizeBeneficiariesSubscriptionUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const [beneficiaries, setBeneficiaries] = useState(stateProxy.read(uiElement.dimensions.mutaBeneficiaries) ?? [])
    const mutaPrincipalSocialSecurityNumber = stateProxy.read(uiElement.dimensions.mutaSocialSecurityNumber)
    const [finalizeBeneficiaryMemberInfo, setFinalizeBeneficiaryMemberInfo] = useState<FinalizeBeneficiaryMemberInfo>()

    const {
      show: showBeneficiaryModal,
      hide: hideBeneficiaryModal,
      visible: visibleBeneficiaryModal,
    } = useDialogVisibility(`modify-member`)

    const referenceSocialSecurityNumbers = useMemo(() => {
      const socialSecurityNumbers: string[] = []
      if (mutaPrincipalSocialSecurityNumber) socialSecurityNumbers.push(mutaPrincipalSocialSecurityNumber)
      const partner = beneficiaries.find((b) => b.type === 'PARTNER')
      if (partner?.socialSecurityNumber) socialSecurityNumbers.push(partner?.socialSecurityNumber)
      return socialSecurityNumbers
    }, [beneficiaries, mutaPrincipalSocialSecurityNumber])

    const onMemberSubmit = useCallback(
      (beneficiary: MutaBeneficiary) => {
        if (!finalizeBeneficiaryMemberInfo) return
        const beneficiariesToUpdate = [...beneficiaries]
        beneficiariesToUpdate[finalizeBeneficiaryMemberInfo.index] = beneficiary
        stateProxy.write(uiElement.dimensions.mutaBeneficiaries, beneficiariesToUpdate)
        setBeneficiaries(beneficiariesToUpdate)
        hideBeneficiaryModal()
      },
      [
        beneficiaries,
        finalizeBeneficiaryMemberInfo,
        hideBeneficiaryModal,
        stateProxy,
        uiElement.dimensions.mutaBeneficiaries,
      ],
    )

    const onBeneficiaryClick = useCallback(
      (beneficiary: MutaBeneficiary, index: number) => {
        const otherBeneficiaries = beneficiaries.filter((b) => b.id !== beneficiary.id)
        setFinalizeBeneficiaryMemberInfo({ beneficiaryToUpdate: beneficiary, otherBeneficiaries, index })
        showBeneficiaryModal()
      },
      [showBeneficiaryModal, beneficiaries],
    )

    return (
      <div>
        {visibleBeneficiaryModal && finalizeBeneficiaryMemberInfo ? (
          <FinalizeMemberModalWrapper
            onClose={hideBeneficiaryModal}
            onSubmit={onMemberSubmit}
            referenceSocialSecurityNumbers={referenceSocialSecurityNumbers}
            {...finalizeBeneficiaryMemberInfo}
          />
        ) : undefined}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          {beneficiaries.length > 0 ? (
            <RowContainerV2>
              {beneficiaries.map((beneficiary, index) => {
                const isComplete = isMemberCompleted(beneficiary)
                return (
                  <RowButtonV2
                    key={beneficiary.firstName + '-' + beneficiary.lastName}
                    avatarLeft={<Avatar src={avatarPerBeneficiaryType[beneficiary.type]} size="50" />}
                    avatarRight={
                      <Chip
                        size="small"
                        backgroundColor={colors[isComplete ? 'green' : 'orange'][200]}
                        textColor={colors[isComplete ? 'green' : 'orange'][900]}
                      >
                        {isComplete ? 'Terminé' : 'A compléter'}
                      </Chip>
                    }
                    primaryText={beneficiary.firstName}
                    secondaryText={
                      beneficiary.birthDate
                        ? calendarDateToString(beneficiary.birthDate, PARIS, 'DATE_SHORT', language) +
                          ' - ' +
                          namingPerBeneficiaryType[beneficiary.type]
                        : namingPerBeneficiaryType[beneficiary.type]
                    }
                    onClick={() => onBeneficiaryClick(beneficiary, index)}
                  />
                )
              })}
            </RowContainerV2>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  },
)

type FinalizeMemberModalWrapperProps = {
  onClose: () => void
  onSubmit: (beneficiary: MutaBeneficiary) => void
  referenceSocialSecurityNumbers: string[]
} & FinalizeBeneficiaryMemberInfo

type UpdateBeneficiary = {
  firstName?: string
  lastName?: string
  birthCity?: string
  birthCountry?: Countries
  socialSecurityNumber?: string
  socialSecurityOrganismNumber?: string
  socialSecurityRank?: number
}

const FinalizeMemberModalWrapper: FunctionComponent<FinalizeMemberModalWrapperProps> = memo(function MemberModalWrapper(
  props: FinalizeMemberModalWrapperProps,
): JSX.Element {
  const {
    onSubmit: onSubmitMember,
    onClose,
    beneficiaryToUpdate,
    referenceSocialSecurityNumbers,
    otherBeneficiaries,
  } = props

  const [idleBeneficiary, setIdleBeneficiary] = useState<MutaBeneficiary>(beneficiaryToUpdate)
  const isDisabledAdult = useMemo(() => {
    if (idleBeneficiary.type === 'CHILD') {
      return idleBeneficiary.isDisabledAdult ?? false
    }
    return false
  }, [idleBeneficiary])

  const linkedFamilyMember = useMemo(() => {
    if (idleBeneficiary.type === 'CHILD') {
      return idleBeneficiary.isLinkedToPartner ? 'PARTNER' : 'YOU'
    }

    return undefined
  }, [idleBeneficiary])

  const updateBeneficiary = useCallback(
    (update: UpdateBeneficiary) => {
      const updateIdleBeneficiary = { ...idleBeneficiary, ...update }
      setIdleBeneficiary(updateIdleBeneficiary)
    },
    [idleBeneficiary],
  )

  const isChild = useMemo(() => {
    return idleBeneficiary.type === 'CHILD'
  }, [idleBeneficiary.type])

  const firstNameLastNameAlreadyExists = useMemo(() => {
    const firstName = idleBeneficiary.firstName
    const lastName = idleBeneficiary.lastName
    if (firstName == null || lastName == null) return false
    return otherBeneficiaries.some(
      (beneficiary) => beneficiary.firstName === firstName && beneficiary.lastName === lastName,
    )
  }, [otherBeneficiaries, idleBeneficiary])

  const canSubmit = useMemo(() => {
    if (firstNameLastNameAlreadyExists) return false
    if (!idleBeneficiary) return false
    if (isMemberCompleted(idleBeneficiary)) return true
    return false
  }, [idleBeneficiary, firstNameLastNameAlreadyExists])

  const onSubmit = useCallback(() => {
    if (!canSubmit) return
    onSubmitMember(idleBeneficiary)
  }, [canSubmit, idleBeneficiary, onSubmitMember])

  const socialRegimeRadioItem = useMemo(() => {
    const regimeEntry = mutaSocialSecurityRegimeEntries.find((entry) => entry[0] === idleBeneficiary.regime)

    if (regimeEntry) return [{ title: regimeEntry[1].label, tooltip: regimeEntry[1].tooltip }]

    return []
  }, [idleBeneficiary.regime])

  const handleChangeFirstName = useCallback(
    (value: string) => updateBeneficiary({ firstName: value }),
    [updateBeneficiary],
  )
  const handleChangeLastName = useCallback(
    (value: string) => updateBeneficiary({ lastName: value }),
    [updateBeneficiary],
  )
  const handleChangeBirthcity = useCallback(
    (value: string) => updateBeneficiary({ birthCity: value }),
    [updateBeneficiary],
  )
  const handleChangeCountry = useCallback(
    (value: Countries) => updateBeneficiary({ birthCountry: value }),
    [updateBeneficiary],
  )
  const handleChangeSocialSecurityNumber = useCallback(
    (value: string) => updateBeneficiary({ socialSecurityNumber: value }),
    [updateBeneficiary],
  )
  const handleChangeSocialSecurityOrganismNumber = useCallback(
    (value: string | undefined) => updateBeneficiary({ socialSecurityOrganismNumber: value }),
    [updateBeneficiary],
  )
  const handleChangeSocialSecurityRank = useCallback(
    (value: number | undefined) => updateBeneficiary({ socialSecurityRank: value }),
    [updateBeneficiary],
  )

  return (
    <MemberModal
      emoji={avatarPerBeneficiaryType[idleBeneficiary.type]}
      title={namingPerBeneficiaryType[idleBeneficiary.type]}
      isPostQuote={true}
      lastNameValue={idleBeneficiary.lastName ?? ''}
      firstNameValue={idleBeneficiary.firstName ?? ''}
      firstNameLastNameAlreadyExists={firstNameLastNameAlreadyExists}
      birthdateValue={{
        year: idleBeneficiary.birthDate?.year ?? 0,
        oneBasedMonth: idleBeneficiary.birthDate?.oneBasedMonth ?? 0,
        oneBasedDay: idleBeneficiary.birthDate?.oneBasedDay ?? 0,
      }}
      cityValue={idleBeneficiary.birthCity ?? ''}
      postCodeValue={idleBeneficiary.zipCode ?? ''}
      countryValue={idleBeneficiary.birthCountry}
      socialSecurityNumberValue={idleBeneficiary.socialSecurityNumber ?? ''}
      socialSecurityOrganismNumberValue={idleBeneficiary.socialSecurityOrganismNumber}
      socialSecurityRankValue={idleBeneficiary.type === 'CHILD' ? idleBeneficiary.socialSecurityRank : undefined}
      socialRegimeValue={
        idleBeneficiary?.regime
          ? (mutaSocialSecurityRegimeEntries.find((regime) => regime[0] === idleBeneficiary?.regime)?.[1].label ?? '')
          : ''
      }
      onClose={onClose}
      onSubmit={onSubmit}
      onFirstNameUpdate={handleChangeFirstName}
      onCityUpdate={handleChangeBirthcity}
      onCountryUpdate={handleChangeCountry}
      onLastNameUpdate={handleChangeLastName}
      onChangeSocialSecurityNumberUpdate={handleChangeSocialSecurityNumber}
      onChangeSocialSecurityOrganismNumberUpdate={handleChangeSocialSecurityOrganismNumber}
      onChangeSocialSecurityRankUpdate={handleChangeSocialSecurityRank}
      onBirthdateUpdate={() => {
        throw new TechnicalError('Invalid handleChangeBirthdate at this step')
      }}
      onPostCodeUpdate={() => {
        throw new TechnicalError('Invalid handleChangePostCode at this step')
      }}
      onChangeSocialRegimeUpdate={() => {
        throw new TechnicalError('Invalid handleChangeSocialRegime at this step')
      }}
      regimes={[]}
      selectedRegime={socialRegimeRadioItem}
      canDelete={false}
      canSubmit={canSubmit}
      /**
       * Child properties
       */
      isChild={isChild}
      isDisabledAdult={isDisabledAdult}
      onChangeIsDisabledAdult={() => {
        throw new TechnicalError('Invalid onChangeIsDisabledAdult at this step')
      }}
      linkedFamilyMember={linkedFamilyMember}
      onChangeLinkedFamilyMemberUpdate={() => {
        throw new TechnicalError('Invalid handleChangeLinkedFamilyMember at this step')
      }}
      referenceSocialSecurityNumbers={referenceSocialSecurityNumbers}
    />
  )
})

const avatarPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: kiss,
  CHILD: child,
  OTHER: personWhiteHair,
}

const namingPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: 'Votre conjoint',
  CHILD: 'Votre enfant',
  OTHER: 'Une personne à charge',
}
