import { amountToString, newAmount } from '@orus.eu/amount'

export function valuesToStrings<P extends number>(values: readonly P[]): string[] {
  return values.map((value) => value.toString(10))
}

export function valuesToLabels<P extends number>(values: readonly P[]): Record<string, string> {
  return Object.fromEntries(
    values.map((value) => [
      value.toString(),
      amountToString(newAmount(value), { addCurrency: true, displayDecimals: false }),
    ]),
  )
}
