import { css } from '@emotion/react'
import { LegacyDialog, RowContainerV2, RowStaticV2, spacing, Text } from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'

export type Activity = { id: string; name: string }

type RemoveActivityDialogProps = {
  activity: Activity

  onRemoveActivity: (activity: Activity) => void
  onClose: () => void
}

export const RemoveActivityDialog = memo<RemoveActivityDialogProps>(function RemoveActivityDialog({
  activity,
  onClose,
  onRemoveActivity,
}) {
  const handleSubmit = useCallback(() => {
    onRemoveActivity(activity)
    onClose()
  }, [activity, onClose, onRemoveActivity])

  return (
    <LegacyDialog
      header={<Text variant="h4">Retirer cette activité</Text>}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitLabel="Retirer cette activité"
      secondaryActionLabel="Annuler"
      onSecondaryAction={onClose}
    >
      <Text variant="body2">
        Confirmez-vous que l’activité ci-dessous ne fait pas partie des prestations que vous exercez ?
      </Text>

      <RowContainerV2
        css={css`
          margin-top: ${spacing[60]};
        `}
      >
        <RowStaticV2 label={activity.name} rightNode={null} />
      </RowContainerV2>
    </LegacyDialog>
  )
})
