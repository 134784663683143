import { css } from '@emotion/react'
import { InputAdornment, type InputBaseComponentProps } from '@mui/material'
import { amountToString, multiplyByNumber, type Amount } from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import { memo, useCallback, useMemo, useState } from 'react'
import { colors } from '../../../../colors.js'
import { Button, spacing, TextField, type ScreenType } from '../../../../index.js'
import { Avatar, Slider, Text, VirtualClickableArea } from '../../../atoms/index.js'

export type Activity = { id: string; name: string; sharePercentage: number }

type ActivitiesDistributionProps = {
  screenType: ScreenType

  activities: Activity[]
  revenue: Amount

  onUpdate: (activities: Activity[]) => void
  onCommit: (activities: Activity[]) => void

  className?: string
}

export const ActivitiesDistribution = memo<ActivitiesDistributionProps>(function ActivitiesDistribution({
  screenType,
  activities,
  revenue,
  onUpdate,
  onCommit,
  className,
}) {
  const totalSharePercentage = useMemo(
    () => activities.reduce((acc, activity) => acc + activity.sharePercentage, 0),
    [activities],
  )
  const isValid = totalSharePercentage === 100

  const [editedActivity, setEditedActivity] = useState<Activity | undefined>()

  const handleChange = useCallback(
    (updatedActivity: Activity, commit: boolean) => {
      if (updatedActivity.sharePercentage < MIN_SHARE) return
      if (updatedActivity.sharePercentage > MAX_SHARE) return

      const newActivities = activities.map((activity) => {
        if (activity.id !== updatedActivity.id) return activity

        return updatedActivity
      })

      onUpdate(newActivities)

      if (commit) onCommit(newActivities)
    },
    [activities, onUpdate, onCommit],
  )

  const handleInputValidation = useCallback(() => {
    setEditedActivity(undefined)

    if (!editedActivity || isNaN(editedActivity.sharePercentage)) return

    handleChange(editedActivity, true)
  }, [editedActivity, handleChange])

  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;

          padding: ${spacing[50]};

          background-color: ${isValid ? colors.blue[200] : colors.red[200]};
          border-radius: ${spacing[30]};
        `}
      >
        <Text variant="subtitle1" color={isValid ? colors.blue[900] : colors.red[500]}>
          {totalSharePercentage}
          {nbsp}%
        </Text>

        <ActivitySharePercentageDetails
          screenType={screenType}
          amount={amountToString(revenue, { displayDecimals: false, addCurrency: true })}
          text="Total de votre chiffre d’affaires HT "
        />
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[30]};

          margin-top: ${spacing[70]};
        `}
      >
        {activities.map((activity) => {
          const minusButton = (
            <MinusPlusButton type="minus" screenType={screenType} activity={activity} onChange={handleChange} />
          )

          const plusButton = (
            <MinusPlusButton type="plus" screenType={screenType} activity={activity} onChange={handleChange} />
          )

          return (
            <div
              key={activity.id}
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;

                padding: ${spacing[50]};
                gap: ${screenType === 'mobile' ? spacing[60] : spacing[30]};

                border: 1px solid ${colors.gray[100]};
                border-radius: ${spacing[30]};
                text-align: center;
              `}
            >
              {editedActivity?.id !== activity.id ? (
                <VirtualClickableArea onClick={() => setEditedActivity(activity)}>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;

                        gap: ${spacing[40]};
                      `}
                    >
                      <Avatar icon="pen-solid" size="10" />
                      <Text variant="subtitle1">
                        {activity.sharePercentage}
                        {nbsp}%
                      </Text>
                    </div>

                    <ActivitySharePercentageDetails
                      screenType={screenType}
                      amount={amountToString(multiplyByNumber(revenue, activity.sharePercentage / 100), {
                        addCurrency: true,
                        displayDecimals: false,
                      })}
                      text={activity.name}
                    />
                  </div>
                </VirtualClickableArea>
              ) : (
                <TextField
                  size="small"
                  inputProps={EDIT_FIELD_BASE_INPUT_PROPS}
                  InputProps={EDIT_FIELD_INPUT_PROPS}
                  autoFocus
                  value={editedActivity.sharePercentage.toString()}
                  onChange={(e) => {
                    const parsed = parseInt(e.target.value)

                    setEditedActivity((activity) => ({
                      ...(activity ? activity : editedActivity),
                      sharePercentage: parsed,
                    }))
                  }}
                  onBlur={handleInputValidation}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleInputValidation()
                    }
                  }}
                  css={css`
                    width: 128px;
                  `}
                />
              )}

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  align-self: stretch;

                  margin-top: ${spacing[30]};
                  gap: ${spacing[50]};
                `}
              >
                {screenType === 'desktop' && minusButton}
                <Slider
                  min={MIN_SHARE}
                  max={MAX_SHARE}
                  step={STEP_SHARE}
                  value={activity.sharePercentage}
                  onChange={(value) => handleChange({ ...activity, sharePercentage: value }, false)}
                  onChangeCommitted={(value) => handleChange({ ...activity, sharePercentage: value }, true)}
                />
                {screenType === 'desktop' && plusButton}
              </div>

              {screenType === 'mobile' && (
                <div
                  css={css`
                    align-self: stretch;

                    display: flex;
                    gap: ${spacing[50]};
                  `}
                >
                  {minusButton}
                  {plusButton}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
})

const MIN_SHARE = 5
const STEP_SHARE = 5
const MAX_SHARE = 95

const EDIT_FIELD_BASE_INPUT_PROPS: InputBaseComponentProps = {
  type: 'number',
  inputMode: 'numeric',
  min: MIN_SHARE,
  max: MAX_SHARE,
  step: STEP_SHARE,
}

const EDIT_FIELD_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">%</InputAdornment>,
}

const MinusPlusButton = memo<{
  type: 'minus' | 'plus'
  screenType: ScreenType
  activity: Activity
  onChange: (updatedActivity: Activity, commit: boolean) => void
}>(function MinusPlusButton({ type, screenType, activity, onChange }) {
  const handleClick = useCallback(() => {
    onChange(
      {
        ...activity,
        sharePercentage: activity.sharePercentage + STEP_SHARE * (type === 'minus' ? -1 : 1),
      },
      true,
    )
  }, [activity, type, onChange])

  return (
    <Button
      css={css`
        flex-grow: 1;
      `}
      variant="secondary"
      icon={type === 'minus' ? 'minus-regular' : 'plus-regular'}
      size={screenType === 'desktop' ? 'medium' : 'large'}
      onClick={handleClick}
    />
  )
})

type ActivitySharePercentageDetailsProps = {
  text: string
  amount: string
  screenType: ScreenType
}

const ActivitySharePercentageDetails = memo<ActivitySharePercentageDetailsProps>(
  function DisplayActivitySharePercentage(props) {
    const { text, amount, screenType } = props

    return screenType === 'desktop' ? (
      <Text variant="body2">
        {text}
        {' - '}
        {amount}
      </Text>
    ) : (
      <>
        <Text variant="body2">{text}</Text>
        <Text variant="body2">{amount}</Text>
      </>
    )
  },
)
