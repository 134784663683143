import { css } from '@emotion/react'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import type { EmailAvailabilityCheckerUiElement } from '@orus.eu/dimensions'
import { PersistentNotification, Spinner, Text, useCrash } from '@orus.eu/pharaoh'
import type { ActionButton } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../../../client'
import { usePermissions } from '../../../../lib/use-permissions'
import { ifStateProxy } from '../if-state-proxy'

export const EmailAvailabilityCheckerSubscriptionUiElementBlock = ifStateProxy<EmailAvailabilityCheckerUiElement>(
  function EmailAvailabilityCheckerSubscriptionUiElementBlock({
    uiElement,
    stateProxy,
    registerNextEnabledContribution,
    setSubscriptionOwner,
    customerId,
  }) {
    const [existingUser, setExistingUser] = useState<UserAccount | null>(null)
    const [isBackOfficeUser, setIsBackOfficeUser] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState(false)
    const dimension = uiElement.dimension
    const email = stateProxy.read(dimension)
    const crash = useCrash()
    const { type: userType } = usePermissions()
    const isPartner = userType === 'partner'

    useEffect(() => {
      if (customerId || !email) {
        registerNextEnabledContribution('email-availability-checker', true)
        return
      }

      let cancelled = false

      registerNextEnabledContribution('email-availability-checker', false)

      setIsBackOfficeUser(false)
      trpc.users.getUserByEmail.query(email).then(
        (user) => {
          if (cancelled) return
          registerNextEnabledContribution('email-availability-checker', !user)
          setExistingUser(user ?? null)
        },
        (err) => {
          if (cancelled) return
          if (err.data.code === 'PRECONDITION_FAILED') {
            setIsBackOfficeUser(true)
          } else {
            crash({ type: 'unexpected-error', err })
          }
        },
      )

      return () => {
        cancelled = true
      }
    }, [crash, email, customerId, registerNextEnabledContribution])

    const changeSubscriptionUser = useCallback(() => {
      if (!existingUser) return

      setSubmitting(true)

      setSubscriptionOwner(existingUser.id)
    }, [existingUser, setSubscriptionOwner])

    const actionButton = useMemo<ActionButton>(
      () => ({
        label: 'Utiliser ce compte client',
        onClick: changeSubscriptionUser,
      }),
      [changeSubscriptionUser],
    )

    return !customerId && existingUser && !isBackOfficeUser ? (
      <div
        css={css`
          position: relative;
        `}
      >
        <PersistentNotification
          css={css`
            opacity: ${submitting ? '0' : '1'};
          `}
          variant="warning"
          title="Compte client existant"
          actionButton={actionButton}
        >
          {isPartner ? (
            'Cette adresse email est déjà utilisée par un client Orus. Le nouveau contrat restera géré par vous et cela ne changera pas la gestion du contrat existant.'
          ) : (
            <>
              Adresse email déjà utilisée par le client :{' '}
              <Text variant="body1Medium" element="span">
                {existingUser.firstName} {existingUser.lastName}
              </Text>
            </>
          )}
        </PersistentNotification>
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            visibility: ${submitting ? 'visible' : 'hidden'};
          `}
        >
          <Spinner size="40" />
        </div>
      </div>
    ) : isBackOfficeUser ? (
      <PersistentNotification variant="danger" title="Compte utilisateur existant">
        Il est impossible de souscrire avec un compte back-office.
      </PersistentNotification>
    ) : (
      <></>
    )
  },
)
