import { type SocialSecurityNumberSubscriptionUiElement } from '@orus.eu/dimensions'
import { useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import {
  getSocialSecurityNumberMapper,
  SocialSecurityNumberFormatter,
} from '@orus.eu/pharaoh/src/components/features/health-cover'
import { useCallback, useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const SocialSecurityNumberSubscriptionUiElementBlock = ifStateProxy<SocialSecurityNumberSubscriptionUiElement>(
  function SocialSecurityNumberSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const socialSecurityNumberDimension = uiElement.dimensions.mutaSocialSecurityNumber
    const birthDateDimension = uiElement.dimensions.birthDate
    const value = stateProxy.read(socialSecurityNumberDimension)
    const birthDate = stateProxy.read(birthDateDimension)
    const handleChange = useCallback(
      (value: string | undefined) => {
        stateProxy.write(socialSecurityNumberDimension, value)
      },
      [socialSecurityNumberDimension, stateProxy],
    )

    const mapper = useMemo(() => {
      return getSocialSecurityNumberMapper({ birthDate })
    }, [birthDate])

    return (
      <div>
        <ValidatedTextFieldFormField
          size={context === 'backoffice' ? 'small' : 'large'}
          label={socialSecurityNumberDimension.displayNames[language]}
          badge={context === 'backoffice' ? <TagBadge tag={socialSecurityNumberDimension.tags?.[0]} /> : <></>}
          value={value}
          placeholder={
            socialSecurityNumberDimension.placeholders
              ? socialSecurityNumberDimension.placeholders[language]
              : undefined
          }
          onChange={handleChange}
          mapper={mapper}
          infoTooltip={socialSecurityNumberDimension.hints ? socialSecurityNumberDimension.hints[language] : undefined}
          InputProps={{ inputComponent: SocialSecurityNumberFormatter }}
          caption={'Exemple : 1 98 06 12 123 987 55'}
          required={uiElement.required}
          highlight={uiElement.highlight}
        />
      </div>
    )
  },
)
