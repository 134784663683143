import type { SurfaceSubscriptionUiElement } from '@orus.eu/dimensions'
import { useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { surfaceMapper } from '../../../../lib/surface'
import { ifStateProxy } from '../if-state-proxy'

export const SurfaceSubscriptionUiElementBlock = ifStateProxy<SurfaceSubscriptionUiElement>(
  function SurfaceSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)

    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        label={dimension.displayNames[language]}
        value={value}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={surfaceMapper}
        infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)
